/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { get, post, postFormData } from 'src/common/utils/api'
import { Contact } from 'src/modules/contact/types/Contact'
import { CustomersBusinessEntitiesEntity as Entity } from '../types/Customer'
import { INDEXES } from './constants'
import transformToFormData from '../utils/transformToFormData'
import { User } from '../types/User'

const taxesDataMapping = {
  'Not applicable': '7c5a1c00-2f8e-476b-97b4-3161cb590b8c',
  'Reverse charge': '3f10c799-d4d4-4243-8722-e2df8bfff014',
  'Zero rated': 'c1cd6e7d-45f5-4ce5-9a6a-4548d821afb4',
  '5%': '4ac8d4d2-3d75-4176-9643-1732f9e58219',
  '6%': '5d8e1190-bbb4-4436-a4d6-5fdc9b192d7e',
  '8%': 'd9038ede-192e-486d-8cce-d06bc4f46c67',
  '9%': 'a513c317-933a-4a7f-96c8-8209c267eca5',
  '10%': '0010590c-d0d2-44c3-88ff-1baeeb8028a3',
  '21%': '574c4795-6705-4561-b4dd-046e2bfe53e1',
}

export const TAXES_OPTIONS = Object.entries(taxesDataMapping).map(
  ([label, id]) => ({
    label,
    value: id, // Assuming you want the label also as the value, adjust if needed
    id,
  }),
)

export interface CreateMutationProp {
  callback?: () => void
}

export interface MuataionProp {
  callback?: (x?: any) => void
}

export interface ImportFileProp {
  callback?: () => void
  inalidateQueryKey?: string
  url: string
}

export interface UpdateMuataionProp {
  callback?: () => void
  opportunityId: string
}
interface AutocompleeteProp {
  index: string
  term: string
  getLabel?: (item: any) => string
  enabled?: boolean
  filter?: string
  afterFilter?: (item: any) => any | null
}

export const useGetAutocompleeteData = ({
  index,
  term = '',
  getLabel,
  filter,
  afterFilter,
  enabled,
}: AutocompleeteProp) => {
  // const activeOfficeId = localStorage.getItem('activeOfficeId')

  return useQuery([index, term, filter], {
    queryFn: () =>
      get(
        `common/search?term=${term}&index=${index}&perPage=${100}${
          filter ? '&' + filter : ''
        }`,
      ),
    retry: false,
    enabled: enabled,
    // refresh cache after 10 seconds (watch the network tab!)
    staleTime: 10 * 1000,
    select: (data: any) => {
      return !afterFilter
        ? data?.data.map((item: any) => ({
            ...item,
            id: item.id,
            value: item.id,
            label: getLabel ? getLabel(item) : `${item?.name}`,
          }))
        : data?.data
            .map((item: any) => ({
              ...item,
              id: item.id,
              value: item.id,
              label: getLabel ? getLabel(item) : `${item?.name}`,
            }))
            .filter(afterFilter)
    },
  })
}
interface UsersProps {
  convertToOptions?: boolean
}

export const useGetUsers = ({ convertToOptions = true }: UsersProps) => {
  return useQuery(['users'], {
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `common/search?term=&index=${INDEXES.USER}&officeId=${activeOfficeId}&perPage=100`,
      )
    },
    retry: false,
    select: (data) => {
      return convertToOptions
        ? data &&
            data.data.map((item: User) => ({
              id: item.id,
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }))
        : data.data
    },
  })
}
export const useGetCustomers = ({ convertToOptions = true }: UsersProps) => {
  return useQuery(['customers'], {
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `common/search?term=&index=${INDEXES.CUSTOMER}&officeId=${activeOfficeId}&perPage=100`,
      )
    },
    retry: false,
    select: (data) => {
      return convertToOptions
        ? data &&
            data.data.map((item: any) => ({
              id: item.id,
              value: item.id,
              label: `${item.name} `,
            }))
        : data.data
    },
  })
}

export const useGetOfficeUsers = ({ convertToOptions = true }: UsersProps) => {
  return useQuery(['users'], {
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `users?page=1&perPage=100&officeIds=like:${activeOfficeId}`,
        {},
        false,
      )
    },
    retry: false,
    select: (data) => {
      return convertToOptions
        ? data &&
            data.data.map((item: User) => ({
              id: item.id,
              value: item.id,
              label: `${item.firstName} ${item.lastName}`,
            }))
        : data.data
    },
  })
}

export const useGetProducts = ({ convertToOptions = true }: UsersProps) => {
  return useQuery(['products', '', ''], {
    queryFn: () => {
      return get(`common/search?term=&index=${INDEXES.PRODUCT}&perPage=100`)
    },
    retry: false,
    select: (data) => {
      return convertToOptions
        ? data &&
            data.data.map((item: any) => ({
              ...item,
              id: item.id,
              value: item.id,
              label: item.partNumber,
            }))
        : data.data
    },
  })
}
export const useGetUserDetails = ({ userId }: { userId: string }) => {
  return useQuery(['user', userId], {
    queryFn: () => {
      // const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(`users/${userId}`)
    },
  })
}
interface ContactEmail {
  email?: string | null
}
export const useGetContactsByEmail = ({ email = null }: ContactEmail) => {
  return useQuery([INDEXES.CONTACT, email], {
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `common/search?term=&index=${INDEXES.CONTACT}&officeId=${activeOfficeId}&filter="email=${email}"`,
      )
    },
    enabled: !!email,
    retry: false,
    select: (data) => {
      return false
      // return data && data.data?.length > 0
    },
  })
}

export const useGetContacts = () => {
  return useQuery(['contacts'], {
    queryFn: () => get('crm/contacts?perPage=100'),
    select: (data) => {
      return (
        data &&
        data.data.map((item: Contact) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}

export const useGetContactsWithCustomerIdOrNullCustomer = (
  customerId: string,
) => {
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  return useQuery(['contacts', customerId], {
    queryFn: () =>
      get(
        `crm/contacts?perPage=100&customerId=eq:${customerId}:or:is:null&officeId=eq:${activeOfficeId}`,
      ),
    select: (data) => {
      return (
        data &&
        data.data.map((item: Contact) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}

export const useGetContactsWithCustomerId = (filter: string) => {
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  return useQuery(['contacts', filter], {
    queryFn: () =>
      get(`crm/contacts?perPage=100&${filter}&officeId=eq:${activeOfficeId}`),
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          ...item,
          value: item.id,
          label: item?.name,
        }))
      )
    },
  })
}

export const useGetContactDetails = (id: string | null) => {
  return useQuery(['contact', id], {
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')

      return get(
        `common/search?term=&index=${INDEXES.CONTACT}&officeId=${activeOfficeId}filter="id=eq:${id}"`,
      )
    },
    enabled: !id,
    select: (data) => {
      return (
        data &&
        data.data.map((item: Contact) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}

export const useGetRoles = () => {
  return useQuery(['acl/roles'], {
    queryFn: () => get('acl/roles'),
    select: (data) => {
      return (
        data?.data?.roles?.map((item: any) => ({
          ...item,
          id: item.id,
          value: item.id,
          label: item.name,
        })) || []
      )
    },
  })
}

export const useGetBusinessEntitiesQuery = (customerId: string) => {
  return useQuery({
    queryKey: ['customer', customerId],
    enabled: !!customerId,
    queryFn: () => get(`crm/customers/${customerId}`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return (
        data.entities?.map((item: Entity) => ({
          ...item,
          value: item.id,
          label: item.companyName,
        })) ||
        [] ||
        []
      )
    },
  })
}

export const useGetContactsByCustomerId = (
  term: string,
  customerId: string | null | undefined,
) => {
  return useQuery({
    queryKey: ['contacts', customerId, term],
    enabled: !!customerId,
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `common/search?term=${term}&index=${INDEXES.CONTACT}&officeId=${activeOfficeId}&perPage=100&filters=customerId=${customerId}"`,
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return (
        data.data?.map((item: any) => ({
          ...item,
          id: item.id,
          value: item.id,
          label: item.name,
        })) || []
      )
    },
  })
}

export const useGetContactsWithNullCustomerId = (
  term: string,
  filterByCustomerId: string | null | undefined,
  hideNullCustomerIds?: boolean,
) => {
  return useQuery({
    queryKey: ['customer', 'null', term],
    enabled: !!filterByCustomerId && !hideNullCustomerIds,
    // staleTime: 1 * 60 * 60 * 1000,
    // cacheTime: 1 * 60 * 60 * 1000,
    queryFn: () => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      return get(
        `common/search?term=${term}&index=${INDEXES.CONTACT}&officeId=${activeOfficeId}&perPage=100`,
      )
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return (
        data.data
          ?.filter((item: any) => item.customer === null)
          .map((item: any) => ({
            ...item,
            id: item.id,
            value: item.id,
            label: item.name,
          })) || []
      )
    },
  })
}

export const useCarriersAutocompleteQuery = () => {
  return useQuery({
    queryKey: [INDEXES.CARRIER],
    queryFn: () => get(`common/carriers`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return (
        data.data?.map((item: any) => ({
          ...item,
          id: item.id,
          value: item.name,
          label: item.name,
        })) || []
      )
    },
  })
}

export interface ContactSubmitValues {
  id?: string
  name: string
  email: string
  phone: string
}

export interface RoleSubmitValues {
  id?: string
  name: string
  permissions: []
}

export const useImportFileMutation = ({
  url,
  callback,
  inalidateQueryKey,
}: ImportFileProp) => {
  const queryClient = useQueryClient()
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  return useMutation({
    mutationFn: (values: any) => {
      const data = transformToFormData({
        ...values,
        officeId: activeOfficeId,
      })
      return postFormData(url, data)
    },
    onSuccess: () => {
      toast.success('Success! File was imported')
      inalidateQueryKey && queryClient.invalidateQueries([inalidateQueryKey])
      callback && callback()
    },
    onError: () => {
      toast.error('File could not be imported! Please try again')
    },
  })
}

export const useCreateContactMutation = ({ callback }: MuataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: ContactSubmitValues) => post('crm/contacts', values),
    onSuccess: (data: any, variables: any) => {
      toast.success('Success! Contact was created')
      queryClient.invalidateQueries(['contacts'])
      callback && callback({ ...data, ...variables?.values })
    },
    onError: () => {
      toast.error('Contact could not be created! Please try again')
    },
  })
}

export const useCreateRolesMutation = ({ callback }: MuataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: RoleSubmitValues) => post('roles', values),
    onSuccess: () => {
      toast.success('Success! Role was created')
      queryClient.invalidateQueries(['roles'])
      callback && callback()
    },
    onError: () => {
      toast.error('Role could not be created! Please try again')
    },
  })
}

export const useGetCountryValues = () => {
  return useQuery(['countries'], {
    queryFn: () => get('common/iso/countries'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.code,
          id: item.code,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetCurrencyValues = () => {
  return useQuery(['currencies'], {
    queryFn: () => get('common/iso/currencies'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.code,
          label: item.code,
          ...item,
        }))
      )
    },
  })
}
export const useGetProductValues = () => {
  return useQuery(['currencies'], {
    queryFn: () => get('common/iso/currencies'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.code,
          ...item,
        }))
      )
    },
  })
}

export const useGetTermsConditionsQuotes = () => {
  return useQuery(['termsConditions'], {
    queryFn: () => get('common/terms-and-conditions'),
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetSeaPorts = () => {
  return useQuery(['seaPorts'], {
    queryFn: () => get('common/geo/seaports'),
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetAirPorts = () => {
  return useQuery(['airPorts'], {
    queryFn: () => get('common/geo/airPorts'),
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetServiceTypes = () => {
  return useQuery(['serviceTypes'], {
    queryFn: () => get('common/service-types'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetPriceTypes = () => {
  return useQuery(['priceTypes'], {
    queryFn: () => get('common/priceTypes'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetContanierTypes = () => {
  return useQuery(['priceTypes'], {
    queryFn: () => get('common/priceTypes'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}

export const useGetIncoterms = () => {
  return useQuery(['incoterms'], {
    queryFn: () => get('common/incoterms'),
    staleTime: 12 * 60 * 60 * 1000,
    cacheTime: 12 * 60 * 60 * 1000,
    select: (data) => {
      return (
        data &&
        data?.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

export const useGetQuotesRequests = () => {
  return useQueries({
    queries: [
      'service-types',
      'terms-and-conditions',
      'geo/seaports',
      'geo/airports',
    ].map((item) => {
      return {
        queryKey: [item],
        queryFn: () => get('common/' + item),
        staleTime: 1 * 60 * 60 * 1000,
        cacheTime: 1 * 60 * 60 * 1000,
        select: (data: any) => {
          try {
            return (
              data?.data?.map((item: any) => ({
                value: item.id,
                label: item.name,
                ...item,
              })) || []
            )
          } catch {
            return []
          }
        },
      }
    }),
  })
}
export const useGetInvoiceRequests = () => {
  return useQueries({
    queries: ['service-types'].map((item) => {
      return {
        queryKey: [item],
        queryFn: () => get('common/' + item),
        staleTime: 1 * 60 * 60 * 1000,
        cacheTime: 1 * 60 * 60 * 1000,
        select: (data: any) => {
          try {
            return (
              data?.data?.map((item: any) => ({
                value: item.id,
                label: item.name,
                ...item,
              })) || []
            )
          } catch {
            return []
          }
        },
      }
    }),
  })
}

export const useGetVessels = () => {
  return useQuery([INDEXES.VESSEL], {
    queryFn: () => get('common/vessels'),
    select: (data) => {
      return (
        data &&
        data.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          ...item,
        }))
      )
    },
  })
}

interface Opportunity {
  id?: string
  name?: string
  serviceType?: string
  customer?: string
  contact?: string[]
  leadId?: string
  status?: string
}

export const useCreateOpportunityMutation = ({ callback }: MuataionProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: Opportunity) => post('sales/opportunities', values),
    onSuccess: (status, variables: Opportunity) => {
      toast.success('Success! Opportunity was created')
      queryClient.invalidateQueries(['opportunities'])
      if (variables.leadId) {
        queryClient.invalidateQueries(['leads'])
        queryClient.invalidateQueries(['lead', variables.leadId])
      }
      callback && callback()
    },
    onError: () => {
      toast.error('Opportunity could not be created! Please try again ')
    },
  })
}

export const useGetOpportunities = () => {
  return useQuery(['opportunities'], {
    queryFn: () => get('sales/opportunities?perPage=100'),
    select: (data) => {
      return (
        data &&
        data.data?.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}

export const useGetOffices = () => {
  return useQuery(['offices'], {
    queryFn: () => get('offices'),
    staleTime: 600000,
  })
}

export const useGetOfficeDetails = (officeId: string) => {
  return useQuery(['offices', officeId], {
    queryFn: () => get(`offices/${officeId}`),
    select: (dbData: any) => {
      return {
        ...dbData?.data?.office,
        ...dbData?.data?.office?.contactDetails,
        logo: Object.values(dbData?.data?.office?.logo || {}),
        taxRates: Object.values(dbData?.data?.office?.taxRates).map(
          (x: any) => x.id,
        ),

        currency: dbData?.data?.office?.currency?.currencyCode,
        dateTimeZone: dbData?.data?.office?.timezone,
        footerData: {
          ...dbData?.data?.office?.footerData,
          bankAccounts:
            Object.values(dbData?.data?.office?.footerData?.bankAccounts)?.map(
              (x: any) => ({
                value: x,
              }),
            ) || [],
          appendinx:
            Object.values(
              dbData?.data?.office?.footerData?.appendinx || {},
            )?.map((x: any) => ({
              value: x,
            })) || [],
        },
      }
    },
  })
}

export const useGetOfficesOptions = () => {
  return useQuery(['offices'], {
    queryFn: () => get('offices'),
    select: (data) => {
      return (
        data &&
        data.data?.map((item: any) => ({
          ...item,
          value: item.id,
          label: item.name,
        }))
      )
    },
  })
}
