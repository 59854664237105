/* eslint-disable @typescript-eslint/no-unused-vars */
import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import PropTypes from 'prop-types'
import { FC } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'
import { useDropzone } from 'react-dropzone'
import { bytesToSize } from '../../utils/bytes-to-size'
import { FileIcon } from './file-icon'

export type File = FileWithPath

interface FileDropzoneProps extends DropzoneOptions {
  size?: 'small' | 'normal'
  files?: File[]
  onRemove?: (file: File) => void
  onRemoveAll?: () => void
  onUpload: (files: File[]) => void
}

export const FileDropzoneMinimal: FC<FileDropzoneProps> = (props) => {
  const {
    size = 'normal',
    files = [],
    onRemove,
    onRemoveAll,
    onUpload,
    ...other
  } = props
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropAccepted: (files) => {
      onUpload(files)
    },
  })

  const hasAnyFiles = files.length > 0

  return (
    <Box>
      <Box {...getRootProps()}>
        <input {...getInputProps()} />
        <Button startIcon={<AttachFileIcon />}>Add files</Button>
      </Box>

      {hasAnyFiles && (
        <Box>
          <List sx={{ display: 'flex', flexWrap: 'wrap' }}>
            {files.map((file) => {
              const extension = file.name.split('.').pop()
              return (
                <Box
                  key={file.path}
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}
                  mr={1}
                >
                  <ListItemIcon sx={{ height: '14px' }}>
                    <FileIcon extension={extension} />
                  </ListItemIcon>
                  <ListItemText
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondary={bytesToSize(file.size)}
                  />
                  <Tooltip title={'Remove ' + file.name}>
                    <IconButton
                      edge="end"
                      onClick={() => onRemove?.(file)}
                      sx={{ alignSelf: 'baseline' }}
                    >
                      <SvgIcon>
                        <XIcon />
                      </SvgIcon>
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            })}
          </List>
        </Box>
      )}
    </Box>
  )
}

FileDropzoneMinimal.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  // From Dropzone
  accept: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  ),
  disabled: PropTypes.bool,
  getFilesFromEvent: PropTypes.func,
  maxFiles: PropTypes.number,
  maxSize: PropTypes.number,
  minSize: PropTypes.number,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  onDrop: PropTypes.func,
  onDropAccepted: PropTypes.func,
  onDropRejected: PropTypes.func,
  onFileDialogCancel: PropTypes.func,
  preventDropOnDocument: PropTypes.bool,
}
