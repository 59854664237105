/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, CardContent } from '@mui/material'
import { useFormik } from 'formik'
import { Modal } from 'src/common/components'
import { useCreatePurchaseOrderMutation } from './usePurchasesRequests'
import { cloneDeep } from 'lodash'
import PurchaseOrderForm from './purchases-form'
import { defaultValues, validationSchema, PurchaseOrder } from './formConfig'
import { QuotesPurchaseOrdersFormProps } from 'src/common/types/Quote'
import Button from 'src/common/components/loadingButton'

const CreateModal = ({
  handleClose,
  open,
  submitCallback,
  quoteId,
}: QuotesPurchaseOrdersFormProps) => {
  const createMutation = useCreatePurchaseOrderMutation({
    callback: async (data: any) => {
      handleClose()
      const newItem = cloneDeep({ ...formik.values, ...data?.data })
      submitCallback && (await submitCallback(newItem))
      formik.resetForm()
    },
    quoteId: quoteId,
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: async (values: PurchaseOrder): Promise<void> => {
      const submitValues = {
        ...values,
        vendorId: values?.vendorId?.id,
      }
      createMutation.mutate({
        values: submitValues,
        setFieldError: formik.setFieldError,
      })
    },
  })

  const handleModalClose = () => {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal
      id="create-purchase-order-modal"
      title={'Create new Purchase'}
      onClose={handleModalClose}
      open={open}
      footer={
        <Box display="flex" p={1} gap={2}>
          <Button
            disabled={formik.isSubmitting || createMutation.isLoading}
            isLoading={createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={formik.submitForm}
            variant="contained"
            id="sales-quotes-purchase-order-modal-save"
          >
            Save
          </Button>
          <Button
            disabled={formik.isSubmitting || createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={handleModalClose}
            variant="outlined"
            id="sales-quotes-purchase-order-modal-cancel"
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <CardContent>
        <PurchaseOrderForm formik={formik} />
      </CardContent>
    </Modal>
  )
}

export default CreateModal
