import * as Yup from 'yup'

export type PurchaseOrder = {
  basePrice: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendorId: any
  description: string
}

export const defaultValues: PurchaseOrder = {
  basePrice: null,
  vendorId: '',
  description: '',
}

export const validationSchema = Yup.object({
  basePrice: Yup.number()
    .typeError('Base price must be a number')
    .min(0, 'Base price must be greater than or equal to 0')
    .required('Base price is required'),
  vendorId: Yup.object().required('Vendor is required'),
  description: Yup.string(),
})
