/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteJwtCookie, getJwtFromCookie } from './auth-cookie'
import _, { replace } from 'lodash'

export const apiUrl = process.env.REACT_APP_RULEWAVE_MICROSERVICES_DEV as string

const removeEmptyField = (obj: Record<string, any>) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === 'object') removeEmptyField(obj[key])
    else if (obj[key] === undefined || obj[key] === null || obj[key] === '')
      delete obj[key]
  })
  return obj
}

function shouldIncludeOfficeId(url: string): boolean {
  //list of urls that should not include officeId
  const excludedUrls = [
    'address',
    'airport',
    'carrier',
    'product',
    'vessel',
    'terms_and_conditions',
    'seaport',
    'operations/files',
    'finance/invoices',
    'finance/purchases',
    'sales/leads',
    'crm/contacts',
  ]
  return !excludedUrls.some((excludedUrl) => url.includes(excludedUrl))
}

interface RequestWithHeaders extends RequestInit {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers: Record<string, any>
}

const requestInterceptor = async (
  request: RequestInit,
): Promise<RequestWithHeaders> => {
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  const token = getJwtFromCookie()

  if (token) {
    const headers: Record<string, string> = {
      ...(request.headers as Record<string, string>),
      Accept: 'application/json',
      'content-type': 'application/json',
      'X-Auth-Token': token,
      officeId: activeOfficeId || '',
    }

    return { ...request, headers }
  } else {
    throw new Error('Not Authorized')
  }
}

const responseInterceptor = async (response: Response) => {
  if (response.status === 401 || response.status === 403) {
    deleteJwtCookie()
    window.location.href = '/login'
  }
  return response
}

async function customFetch(
  url: string,
  init?: RequestInit,
  setFieldError?: (field: string, value: string | undefined) => void,
): Promise<Response> {
  const interceptedInit = await requestInterceptor(init || {})

  const response = await fetch(apiUrl + url, interceptedInit)

  if (response.ok) {
    return responseInterceptor(response)
  } else {
    // Parse the response body to JSON to read the error message
    const responseBody = await response.json()
    const errorMessage = responseBody.error || 'An unknown error occurred'

    if (responseBody?.errors)
      Object.keys(responseBody?.errors).forEach((field) => {
        const errorMessages = responseBody?.errors?.[field]
        const formattedErrorMessage = errorMessages?.join('\n')
        setFieldError?.(field, formattedErrorMessage)
      })

    // Throw a new error with the specific message from the response
    throw new Error(errorMessage || 'An error happened')
  }
}
async function plainFetch(url: string, init?: RequestInit): Promise<Response> {
  const interceptedInit = await requestInterceptor(init || {})

  const response = await fetch(apiUrl + url, interceptedInit)

  if (response.ok) {
    return responseInterceptor(response)
  } else {
    const errorBody = await response.json().catch(() => ({})) // Fallback to an empty object if JSON parsing fails
    throw errorBody
  }
}

export function signInPost(url = '', data = {}) {
  return fetch(apiUrl + url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  })
}

export function fogotPasswordPost(url = '', data = {}) {
  return fetch(apiUrl + url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  })
}
export function resetPasswordPost(url = '', data = {}) {
  return fetch(apiUrl + url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  })
}

export async function post(
  url = '',
  data: Record<string, any> = {},
  setFieldError?: (field: string, value: string | undefined) => void,
) {
  const cleanedData = removeEmptyField(data)
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  return customFetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify({ ...cleanedData, officeId: activeOfficeId }),
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
    },
    setFieldError,
  )
    .then((data) => {
      return data.json()
    })
    .catch((e: any) => {
      throw e
    })
}
export async function post_plain(url = '', data: Record<string, any> = {}) {
  const cleanedData = removeEmptyField(data)
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  return plainFetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...cleanedData, officeId: activeOfficeId }),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  }).then((data) => {
    return data.json()
  })
}
export async function postFile(
  url = '',
  data: Record<string, any> = {},
  fileName?: string,
) {
  const cleanedData = removeEmptyField(data)
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  return customFetch(url, {
    method: 'POST',
    body: JSON.stringify({ ...cleanedData, officeId: activeOfficeId }),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  })
    .then(async (data) => {
      if (!data.ok) {
        throw new Error('Network response was not ok')
      }
      const blob = await data.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${fileName}.pdf`
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)
      return blob
    })
    .catch((e: any) => {
      throw e
    })
}

export async function postFormData(
  url: string,
  data: FormData,
  setFieldError?: (field: string, value: string | undefined) => void,
) {
  const token = getJwtFromCookie()
  const response = await fetch(apiUrl + url, {
    method: 'POST',
    body: data,
    headers: {
      'X-Auth-Token': token || '',
    },
  }).then((data) => data)

  if (response.ok) {
    const data = responseInterceptor(response)
    return (await data).json()
  } else {
    // Parse the response body to JSON to read the error message
    const responseBody = await response.json()
    const errorMessage = responseBody.error || 'An unknown error occurred'

    if (responseBody?.errors)
      Object.keys(responseBody?.errors).forEach((field) => {
        const errorMessages = responseBody?.errors?.[field]
        const formattedErrorMessage = errorMessages?.join('\n')

        setFieldError?.(field, formattedErrorMessage)
      })

    // Throw a new error with the specific message from the response
    throw new Error(errorMessage || 'An error happened')
  }

  // if (response.ok) {
  //   return responseInterceptor(response)
  // } else {
  //   console.error(response)
  //   throw new Error('An error happened')
  // }
}

export async function postDelete(url = '', data = {}) {
  return customFetch(url, {
    method: 'DELETE',
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'content-type': 'application/json',
    },
  }).then((data) => data.json())
}

export async function put(
  url = '',
  data = {},
  setFieldError?: (field: string, value: string | undefined) => void,
) {
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  return customFetch(
    url,
    {
      method: 'PUT',
      body: JSON.stringify({ ...data, officeId: activeOfficeId }),
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
    },
    setFieldError,
  ).then((data) => data.json())
}

export async function putFormData(
  url: string,
  data: FormData,
  setFieldError?: (field: string, value: string | undefined) => void,
) {
  const token = getJwtFromCookie()
  const response = await fetch(apiUrl + url, {
    method: 'PUT',
    body: data,
    headers: {
      'X-Auth-Token': token || '',
    },
  }).then((data) => data)
  if (response.ok) {
    const data = responseInterceptor(response)
    return (await data).json()
  } else {
    // Parse the response body to JSON to read the error message
    const responseBody = await response.json()
    const errorMessage = responseBody.error || 'An unknown error occurred'

    if (responseBody?.errors)
      Object.keys(responseBody?.errors).forEach((field) => {
        const errorMessages = responseBody?.errors?.[field]
        const formattedErrorMessage = errorMessages?.join('\n')
        setFieldError?.(field, formattedErrorMessage)
      })

    // Throw a new error with the specific message from the response
    throw new Error(errorMessage || 'An error happened')
  }
}

export async function get(
  url = '',
  options?: RequestInit | undefined,
  filterByOfficeId = true,
) {
  const activeOfficeId = localStorage.getItem('activeOfficeId')
  let finalUrl = url

  if (filterByOfficeId && shouldIncludeOfficeId(url)) {
    finalUrl = url.includes('?')
      ? `${url}&officeId=${activeOfficeId}`
      : `${url}?officeId=${activeOfficeId}`
  }

  return customFetch(finalUrl, {
    method: 'GET',
    ...options,
  })
    .then((data) => data.json())
    .catch((e: any) => {
      console.log(e)
      return null
    })
}
export async function get_plain(url = '') {
  const response = await fetch(apiUrl + url, { method: 'GET' })

  // if (response.ok) {
  return response
  // }
  // else {
  //   throw new Error('An error happened')
  // }
}

function replaceLeadingAmpersand(filterStr: string) {
  const target = '&filters="&'
  const replacement = '&filters="'

  const result = _.replace(filterStr, target, replacement)
  return result
}

interface SortingColumn {
  id: string
  desc: boolean
}
export async function infiniteGet(
  url = '',
  start: number,
  size: number,
  sorting: SortingColumn | null = null,
  filters?: string,
  generalSearch?: string,
  useSearchApi?: boolean,
  dontSendOfficeId = false,
) {
  let sortingString = ''
  if (sorting) {
    sortingString = `&orderBy=${sorting.id}&orderDirection=${
      sorting.desc ? 'desc' : 'asc'
    }`
  }
  // else {
  //   sortingString = `&orderBy=created_at&orderDirection=desc`
  // }
  let filtersStr = ''
  if (filters && filters !== '&') {
    filtersStr = filters
  }

  const activeOfficeId = localStorage.getItem('activeOfficeId')
  const officeIdParam =
    !dontSendOfficeId && shouldIncludeOfficeId(url)
      ? `&officeId=${activeOfficeId}`
      : ''

  const baseGeneralSearch = `common/search?term=${
    generalSearch || ''
  }&index=${url}&page=${
    start || 1
  }&perPage=${size}${sortingString}${officeIdParam}`

  const baseOther = `${url}${url.includes('?') ? '&' : '?'}page=${
    start || 1
  }&perPage=${size}${sortingString}${officeIdParam}`

  let filterPart = filtersStr ? `&filters="${filtersStr}"` : ''
  filterPart = replace(filterPart, /like:|eq:/g, '')
  filterPart = replace(filterPart, /&$/, '')
  filterPart = replaceLeadingAmpersand(filterPart)
  const finalUrl = useSearchApi
    ? `${baseGeneralSearch}${filterPart}`
    : `${baseOther}${filtersStr}`

  return customFetch(finalUrl, {
    method: 'GET',
  }).then((data) => data.json())
}
