import { useEffect } from 'react'

export const useDocumentTitle = (module: string, reference: string) => {
  useEffect(() => {
    if (reference) {
      // Set the document title to the file number
      document.title = `${module}: #${reference}`
    } else {
      document.title = 'Rulewave Evo'
    }

    // Cleanup function to reset the title when the component unmounts
    return () => {
      document.title = 'Rulewave Evo'
    }
  }, [module, reference])
}
