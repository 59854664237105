import { isArray, isObject } from 'lodash'

export const keyMapping: Record<string, string> = {
  serviceType: 'Service Type',
  serviceTypeItem: 'Service Item',
  quantity: 'Quantity',
  unitPrice: 'Unit Price',
  description: 'Description',
  remarks: 'Remarks',
  status: 'Status',
  contactId: 'Contact',
  containerType: 'Container Type',
  currency: 'Currency',
  customerId: 'Customer',
  discount: 'Discount',
  mot: 'Method of transport',
  priceType: 'Price type',
  reference: 'Reference',
  taxRate: 'Tax rate',
  lineItems: 'Service Items',
  packages: 'Packages',
  terms: 'Terms and Conditions',
  pickUpPort: 'Loading port',
  pickupAddress: 'Loading Address',
  deliveryAddress: 'Discharge Address',
  deliveryPort: 'Discharge port',
  dimensions: 'Dimensions',
  qty: 'Quantity',
  weight: 'Weight',
  unit: 'Unit',
  volume: 'Volume',
  value: 'Value',
  length: 'Length',
  height: 'Height',
  width: 'Width',
  packageType: 'Package Type',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function displayReadableFormat(obj: any, level = 0): string {
  let result = ''
  const indent = '  '.repeat(level)
  for (const key in obj) {
    const readableKey = keyMapping[key] || key
    const value = obj[key]

    if (isObject(value) && !isArray(value)) {
      result += `${indent}${readableKey}:\n`
      result += displayReadableFormat(value, level + 1)
    } else {
      const displayValue = value === null ? 'N/A' : value
      result += `${indent}${readableKey}: '${displayValue}\n`
    }
  }
  return result
}
