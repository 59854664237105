import { pick, isEqual } from 'lodash'

const compareObjects = (obj1: object, obj2: object, fieldsArray: string[]) => {
  const obj1Properties = pick(obj1, fieldsArray)
  const obj2Properties = pick(obj2, fieldsArray)

  return isEqual(obj1Properties, obj2Properties)
}

export default compareObjects
