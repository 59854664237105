/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SelectRow {
  id: string
  row: any
  tableKey: string
}

interface SelectRowState {
  selectedRows: {
    [tableKey: string]: string[]
  }
}

const initialState: SelectRowState = {
  selectedRows: {},
}

const reducers = {
  addSelectRow(state: SelectRowState, action: PayloadAction<SelectRow>) {
    const { tableKey, id, row } = action.payload
    if (!state.selectedRows[tableKey]) {
      state.selectedRows[tableKey] = []
    }
    if (!state.selectedRows[tableKey].includes(id)) {
      state.selectedRows[tableKey].push(row)
    }
  },
  removeSelectRow(state: SelectRowState, action: PayloadAction<SelectRow>) {
    const { tableKey, id } = action.payload
    state.selectedRows[tableKey] = state.selectedRows[tableKey].filter(
      (row: any) => row?.id !== id,
    )
  },
  unselectAllSelectRows(state: SelectRowState, action: PayloadAction<string>) {
    const tableKey = action.payload
    state.selectedRows[tableKey] = []
  },
  selectAllSelectRows(
    state: SelectRowState,
    action: PayloadAction<{ tableKey: string; ids: string[] }>,
  ) {
    const { tableKey, ids } = action.payload
    state.selectedRows[tableKey] = ids
  },
}

export const slice = createSlice({
  name: 'selectRow',
  initialState,
  reducers,
})

export const {
  addSelectRow,
  removeSelectRow,
  unselectAllSelectRows,
  selectAllSelectRows,
} = slice.actions

export const { reducer } = slice
