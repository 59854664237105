import { Box, CardContent } from '@mui/material'
import { useFormik } from 'formik'
import { Modal } from 'src/common/components'
import VendorForm from '../vendors/form/vendorForm'
import { useCreateVendorMutation } from './useVendorsRequests'
import {
  defaultValues,
  validationSchema,
  VendorsModalProps,
} from './formConfigs'
import Button from 'src/common/components/loadingButton'

const CreateModal = ({
  handleClose,
  open,
  submitCallback,
}: VendorsModalProps) => {
  const handleModalClose = () => {
    handleClose()
    formik.resetForm()
  }
  const createMutation = useCreateVendorMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: async (data: any) => {
      handleClose()
      submitCallback && (await submitCallback(data))
      formik.resetForm()
    },
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema,
    onSubmit: async (values): Promise<void> => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')
      const submitValues = {
        ...values,
        countryCode: values.country,
        officeId: activeOfficeId,
      }

      createMutation.mutate({
        values: submitValues,
        setFieldError: formik.setFieldError,
      })
    },
  })
  return (
    <Modal
      id="create-vendor-modal"
      title={'Create new vendor'}
      onClose={handleModalClose}
      open={open}
      footer={
        <Box display="flex" p={1} gap={2}>
          <Button
            disabled={formik.isSubmitting || createMutation.isLoading}
            isLoading={createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={formik.submitForm}
            variant="contained"
          >
            Save
          </Button>
          <Button
            disabled={formik.isSubmitting || createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={handleModalClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <CardContent>
        <VendorForm formik={formik} />
      </CardContent>
    </Modal>
  )
}

export default CreateModal
