/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from 'react'
import { TextField, Grid, Autocomplete } from '@mui/material'
import { useGetPlace, useGetPlaces } from 'src/common/apis/places'
import debounce from 'lodash/debounce'
import { CircularProgress } from '@mui/material'
import { useGetCountryValues } from 'src/modules/offices/useOfficeRequests'
import AutocompleteWithCreate from './common/autocomplete-with-create'
import { get } from 'lodash'

const dispalyConfig = {
  place: true,
  city: true,
  street: true,
  country: true,
  zipCode: true,
}
const disableConfig = {
  place: false,
  city: false,
  street: false,
  country: false,
  zipCode: false,
}
interface GooglePlacesAutocompleteProps {
  formik: any
  name: string
  variant?: 'outlined' | 'standard' | 'filled'
  label?: string
  displayFields?: {
    place?: boolean
    city?: boolean
    street?: boolean
    country?: boolean
    zipCode?: boolean
  }
  disableFields?: {
    place?: boolean
    city?: boolean
    street?: boolean
    country?: boolean
    zipCode?: boolean
  }
  layout?: 'normal' | 'full'
  wrapperName?: string
  readOnly?: boolean
}

function GooglePlacesAutocomplete({
  formik,
  name,
  variant = 'outlined',
  displayFields,
  disableFields,
  layout = 'normal', // 'normal' | 'full'
  label,
  wrapperName,
  readOnly,
}: GooglePlacesAutocompleteProps) {
  const mainInputName = wrapperName ? `${wrapperName}.${name}` : name
  const mainInputValue = get(formik.values, mainInputName)
  const [instantValue, setInstantValue] = useState('')
  const disableProps = { ...disableConfig, ...disableFields }
  const displayProps = { ...dispalyConfig, ...displayFields }

  const [selectedPlaceId, setSelectedPlaceId] = useState('')
  const countryValues = useGetCountryValues()

  const countryName = wrapperName ? `${wrapperName}.country` : 'country'
  const cityName = wrapperName ? `${wrapperName}.city` : 'city'
  const streetName = wrapperName ? `${wrapperName}.street` : 'street'
  const zipCodeName = wrapperName ? `${wrapperName}.zipCode` : 'zipCode'

  const countryValue = get(formik.values, countryName)

  const CountryInput = useCallback(() => {
    return (
      <AutocompleteWithCreate
        formik={formik}
        options={countryValues.data || []}
        name={countryName}
        label="Country"
        isLoading={countryValues.isLoading}
        allowCreate={false}
        readOnly={readOnly}
        variant={variant}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countryValues.data,
    countryValues.isLoading,
    formik.errors,
    formik.touched,
    countryValue,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetInputValue = useCallback(
    debounce((changedValue: string) => {
      if (
        changedValue !== 'undefined' &&
        (!!changedValue || changedValue === '')
      ) {
        return formik.setFieldValue(mainInputName, changedValue)
      }
    }, 500),
    [],
  )

  const placesQuery = useGetPlaces(mainInputValue)
  const placeQuery = useGetPlace(selectedPlaceId)

  useEffect(() => {
    if (placeQuery?.data) {
      if (wrapperName) {
        formik.values[wrapperName].country = placeQuery.data?.countryCode
        // formik.values[wrapperName].countryCode = placeQuery.data?.countryCode
        formik.values[wrapperName].city = placeQuery.data?.city
        formik.values[wrapperName].zipCode = placeQuery.data?.zipCode
        formik.values[wrapperName].street = placeQuery.data?.street
      } else {
        formik.values.country = placeQuery.data?.countryCode
        // formik.values.countryCode = placeQuery.data?.countryCode
        formik.values.city = placeQuery.data?.city
        formik.values.zipCode = placeQuery.data?.zipCode
        formik.values.street = placeQuery.data?.street
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeQuery.isLoading])

  const isFullWidth = layout === 'full'
  const shrink = (name: string) =>
    !!(
      get(formik.values, name) === 0 ||
      get(formik.values, name) ||
      instantValue
    )

  return (
    <Grid container>
      {displayProps.place && (
        <Grid item xs={12}>
          <Autocomplete
            freeSolo
            options={placesQuery?.data || []}
            getOptionLabel={(option: any) => `${option.description}`}
            filterOptions={(options) => options}
            onInputChange={(event, newInputValue) => {
              debouncedSetInputValue(newInputValue)
              setInstantValue(newInputValue)
            }}
            onChange={(event, newValue: any) => {
              setSelectedPlaceId(newValue?.id || '')
            }}
            readOnly={readOnly}
            disabled={disableProps.place}
            onBlur={formik.handleBlur}
            renderInput={(params) => (
              <TextField
                {...params}
                autoComplete="false"
                label={label || 'Address Name'}
                value={mainInputValue}
                placeholder={mainInputValue || ''}
                name={mainInputName}
                error={
                  !!(
                    get(formik.touched, mainInputName) &&
                    get(formik.errors, mainInputName)
                  )
                }
                helperText={
                  get(formik.touched, mainInputName) &&
                  get(formik.errors, mainInputName)
                }
                variant={variant}
                fullWidth
                margin="normal"
                disabled={disableProps.place}
                InputLabelProps={{ shrink: shrink(mainInputName) }}
                onBlur={formik.handleBlur}
                InputProps={{
                  ...params.InputProps,
                  readOnly: readOnly,
                  endAdornment: (
                    <>
                      {placesQuery.isLoading && placesQuery.isInitialLoading ? (
                        <CircularProgress
                          size={18}
                          style={{
                            position: 'absolute',
                            right: 10,
                            top: 20,
                            color: 'red',
                          }}
                        />
                      ) : (
                        params.InputProps.endAdornment
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
        </Grid>
      )}

      <Grid container columnSpacing={1} justifyContent="space-between">
        {displayProps.street && (
          <Grid item xs={12} sm={isFullWidth ? 12 : 6}>
            <TextField
              label="Street"
              variant={variant}
              fullWidth
              margin="normal"
              error={
                !!(
                  get(formik.touched, streetName) &&
                  get(formik.errors, streetName)
                )
              }
              helperText={
                get(formik.touched, streetName) &&
                get(formik.errors, streetName)
              }
              InputLabelProps={{
                shrink: shrink(streetName),
              }}
              InputProps={{
                readOnly: readOnly,
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name={streetName}
              value={get(formik.values, streetName)}
              disabled={disableProps.street}
            />
          </Grid>
        )}

        {displayProps.zipCode && (
          <Grid item xs={12} sm={isFullWidth ? 12 : 6}>
            <TextField
              label="Postal Code"
              variant={variant}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: readOnly,
              }}
              error={
                !!(
                  get(formik.touched, zipCodeName) &&
                  get(formik.errors, zipCodeName)
                )
              }
              helperText={
                get(formik.touched, zipCodeName) &&
                get(formik.errors, zipCodeName)
              }
              InputLabelProps={{ shrink: shrink(zipCodeName) }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              name={zipCodeName}
              value={get(formik.values, zipCodeName)}
              // InputLabelProps={{ shrink: true }}
              disabled={disableProps.zipCode}
            />
          </Grid>
        )}
      </Grid>
      <Grid container columnSpacing={1} justifyContent="space-between">
        {displayProps.city && (
          <Grid item xs={12} sm={isFullWidth ? 12 : 6}>
            <TextField
              label="City"
              name={cityName}
              value={get(formik.values, cityName)}
              variant={variant}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: readOnly,
              }}
              error={
                !!(
                  get(formik.touched, cityName) && get(formik.errors, cityName)
                )
              }
              helperText={
                get(formik.touched, cityName) && get(formik.errors, cityName)
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              InputLabelProps={{ shrink: shrink(cityName) }}
              disabled={disableProps.city}
            />
          </Grid>
        )}
        {displayProps.country && (
          <Grid item xs={12} sm={isFullWidth ? 12 : 6} mt={2}>
            <CountryInput />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default GooglePlacesAutocomplete
