import React from 'react'
import {
  format,
  parseISO,
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
} from 'date-fns'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Box, Tooltip, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type TimeAgoProps = {
  timestamp: string
  longFormat?: boolean
  longDateFormat?: string
  showExactTime?: boolean
  returnString?: boolean
}

const TimeAgo = ({
  timestamp,
  longFormat = false,
  longDateFormat = 'MMMM do, yyyy H:mm',
  showExactTime = false,
}: TimeAgoProps) => {
  const date = parseISO(timestamp)
  const now = new Date()
  const theme = useTheme()

  const timePeriodInDays = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  )
  const minutesFormat = longFormat ? ' minutes ago' : 'm'
  const hoursFormat = longFormat ? ' hours ago' : 'h'
  const daysFormat = longFormat ? ' days ago' : 'd'

  const secondsAgo = differenceInSeconds(now, date)
  const minutesAgo = differenceInMinutes(now, date)
  const hoursAgo = differenceInHours(now, date)

  let timeAgo: string

  if (secondsAgo < 60) {
    timeAgo = 'Just Now'
  } else if (minutesAgo < 60) {
    timeAgo = `${minutesAgo}${minutesFormat}`
  } else if (timePeriodInDays < 1) {
    const remainingMinutes = minutesAgo % 60
    const remainingMinutesDisplay =
      remainingMinutes > 0 ? remainingMinutes + minutesFormat : ''

    timeAgo = `${hoursAgo}${hoursFormat} ${remainingMinutesDisplay}`
  } else if (timePeriodInDays < 10) {
    timeAgo = timePeriodInDays + daysFormat
  } else {
    timeAgo = format(date, longDateFormat)
  }

  const exactTime = format(date, longDateFormat)

  return (
    <Tooltip title={exactTime}>
      <Box
        sx={{ color: theme.palette.grey[500] }}
        display={'flex'}
        alignItems={'center'}
        gap={0.5}
      >
        <AccessTimeIcon fontSize={'small'} />
        {showExactTime ? (
          exactTime
        ) : (
          <Typography variant="body2">{timeAgo}</Typography>
        )}
      </Box>
    </Tooltip>
  )
}

export const TimeAgoString = ({
  timestamp,
  longFormat = false,
  longDateFormat = 'MMMM do, yyyy H:mm',
  showExactTime = false,
  returnString = false,
}: TimeAgoProps) => {
  const date = parseISO(timestamp)
  const now = new Date()

  const timePeriodInDays = Math.floor(
    (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24),
  )
  const minutesFormat = longFormat ? ' minutes ago' : 'm'
  const hoursFormat = longFormat ? ' hours ago' : 'h'
  const daysFormat = longFormat ? ' days ago' : 'd'

  const secondsAgo = differenceInSeconds(now, date)
  const minutesAgo = differenceInMinutes(now, date)
  const hoursAgo = differenceInHours(now, date)

  let timeAgo: string

  if (secondsAgo < 60) {
    timeAgo = 'Just Now'
  } else if (minutesAgo < 60) {
    timeAgo = `${minutesAgo}${minutesFormat}`
  } else if (timePeriodInDays < 1) {
    const remainingMinutes = minutesAgo % 60
    const remainingMinutesDisplay =
      remainingMinutes > 0 ? remainingMinutes + minutesFormat : ''

    timeAgo = `${hoursAgo}${hoursFormat} ${remainingMinutesDisplay}`
  } else if (timePeriodInDays < 10) {
    timeAgo = timePeriodInDays + daysFormat
  } else {
    timeAgo = format(date, longDateFormat)
  }

  const exactTime = format(date, longDateFormat)
  if (returnString) {
    return showExactTime ? exactTime : timeAgo
  }
}

export default TimeAgo
