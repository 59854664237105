/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import { Tabs } from 'src/common/components'
import HistoryTimeline from 'src/common/components/history'
import { useGetQuoteQuery } from './useQuotesRequests'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { paths } from 'src/navigator/paths'
import {
  STATUS,
  Status,
  StatusKey,
  StatusValue,
} from 'src/common/components/statuses'
import { Notes } from './tabs/notes'
// import PreviewModal from './preview/modal'
import { displayReadableFormat } from 'src/common/utils/historyReadObjects'
import PurchaseOrdersOverview from './purchases/overview'
import Button from 'src/common/components/loadingButton'
import PreviewQuote from './preview'
import { useDocumentTitle } from 'src/common/hooks/use-documnet-title'

const EditQuotePage = () => {
  const navigate = useNavigate()
  const [, setSearchParams] = useSearchParams()

  const { quoteId = '' } = useParams()
  const quoteQuery = useGetQuoteQuery(quoteId || '')

  const reference = quoteQuery?.isLoading ? '' : quoteQuery?.data?.reference

  useDocumentTitle('Quote', reference || '')

  const isUpdate = !!quoteId
  const tabsId = 'quotes-details-tabs'
  const isErrorFetch = isUpdate && quoteQuery.isError
  if (isErrorFetch)
    return (
      <Box component="main" py={4}>
        <Container maxWidth="xl">
          <Alert color="error">
            There was a problem geting the data for this quote{' '}
          </Alert>
          <Button
            id="sales-quotes-cancel"
            variant="outlined"
            onClick={() => navigate(paths.quotes.index)}
            isLoading={quoteQuery.isLoading}
          >
            Close
          </Button>
        </Container>
      </Box>
    )

  return (
    <Box component="main" py={1} mb={5}>
      <Container maxWidth="xl" sx={{ mb: 4 }}>
        <Stack spacing={1}>
          <Box p={2}>
            <Tabs
              id={tabsId}
              setActiveTab={(tab) => setSearchParams({ activeTab: `${tab}` })}
              contorlled
              items={[
                {
                  title: (
                    <div>
                      Quote
                      {quoteQuery?.isLoading && (
                        <CircularProgress size={'small'} />
                      )}
                    </div>
                  ),
                  content: <PreviewQuote />,
                },
                {
                  title: 'Notes',
                  content: (
                    <Card sx={{ p: 4 }}>
                      <Notes quoteId={quoteId} />
                    </Card>
                  ),
                },
                {
                  title: 'History',
                  content: (
                    <Card sx={{ p: 4 }}>
                      <HistoryTimeline
                        objectId={quoteId}
                        fields={[
                          {
                            field: 'status',
                            name: 'Status',
                            component: (item: StatusKey) => (
                              <Status
                                key={item}
                                status={
                                  STATUS[item as StatusKey] as StatusValue
                                }
                              />
                            ),
                          },
                          {
                            field: 'customerReference',
                            name: 'Customer Reference',
                            component: (item: any) => item,
                          },
                          {
                            field: 'lineItems',
                            name: 'Service Items',
                            component: (item: any) => {
                              const dt = item ? JSON.parse(item) : null

                              return (
                                <>
                                  {dt.map((itm: any, index: number) => (
                                    <Typography color={'inherit'} key={index}>
                                      {displayReadableFormat(itm)}
                                    </Typography>
                                  ))}
                                </>
                              )
                            },
                          },
                          {
                            field: 'packages',
                            name: 'Commodity details',
                            component: (item: any) => {
                              const dt = item ? JSON.parse(item) : null

                              return (
                                <>
                                  {dt.map((itm: any, index: number) => (
                                    <Typography color={'secondary'} key={index}>
                                      {displayReadableFormat(itm)}
                                    </Typography>
                                  ))}
                                </>
                              )
                            },
                          },
                        ]}
                      />
                    </Card>
                  ),
                },
                {
                  title: 'Purchases',
                  content: (
                    <Card sx={{ p: 4 }}>
                      <PurchaseOrdersOverview
                        quote={quoteQuery.data}
                        isLoading={quoteQuery.isLoading}
                      />
                    </Card>
                  ),
                },
              ]}
            />
          </Box>
        </Stack>
      </Container>
    </Box>
  )
}

export default EditQuotePage
