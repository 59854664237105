/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material'

import { useTheme } from '@mui/material/styles'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import PropTypes from 'prop-types'
import { FC, useEffect, useState } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'
import { useModal } from 'src/common/hooks/use-modal'
import { bytesToSize } from '../../utils/bytes-to-size'
import { FileIcon } from '../file-dropzone/file-icon'
import { FilePreview } from './types'

export type File = FileWithPath

interface FileDropzoneProps extends DropzoneOptions {
  size?: 'small' | 'normal'
  files?: File[]
  onRemove?: (file: FileWithPath) => void
  readOnly?: boolean
  hideFilePreview?: boolean
  allowedFileTypes?: string[]
  maxFileSize?: number
  setError?: (e: boolean) => void
}

export const FileList: FC<FileDropzoneProps> = (props) => {
  const {
    size = 'normal',
    files = [],
    onRemove,
    readOnly = false,
    hideFilePreview = true,
    allowedFileTypes,
    maxFileSize,
    setError,
  } = props
  const theme = useTheme()

  const hasAnyFiles = files.length > 0
  const [selectedFilePreview, setSelectedFilePreview] = useState<string | null>(
    null,
  )

  const [selectedFile, setSelectedFile] = useState<FilePreview | null>(null)

  const {
    handleOpen: handleOpenModal,
    handleClose: handleCloseModal,
    open,
  } = useModal()

  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null,
  )

  const handleNext = () => {
    if (selectedFileIndex !== null && selectedFileIndex < files.length - 1) {
      handleOpen(files[selectedFileIndex + 1])
    }
  }

  const handlePrev = () => {
    if (selectedFileIndex !== null && selectedFileIndex > 0) {
      handleOpen(files[selectedFileIndex - 1])
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = (file: any) => {
    setSelectedFileIndex(files.indexOf(file))
    if (!file.mimeType && file.type) {
      //local file
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedFilePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setSelectedFilePreview(file.path)
    }
    setSelectedFile(file)
    handleOpenModal()
  }

  const handleClose = () => {
    handleCloseModal()
  }
  useEffect(() => {
    const hasSomeError = files.some(
      (file: any) => !allowedFileTypes?.includes(file.type || file.mimeType),
    )

    setError && setError(hasSomeError)
  }, [allowedFileTypes, files, setError])

  return (
    <>
      {hasAnyFiles && (
        <Box>
          <List
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            {files.map((file: any, index) => {
              const extension = file.name.split('.').pop()
              let errorOnTypeValidation = false
              if (!readOnly) {
                errorOnTypeValidation =
                  !!allowedFileTypes &&
                  !allowedFileTypes?.includes(file.type || file.mimeType)
                setError && setError(errorOnTypeValidation)
              } else {
                setError && setError(false)
              }

              return (
                <Box
                  key={file.path || index}
                  sx={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    alignItems: 'center',
                  }}
                  mr={1}
                >
                  {!hideFilePreview && (
                    <ListItemIcon
                      sx={{ height: '18px' }}
                      onClick={() => handleOpen(file)}
                    >
                      <FileIcon extension={extension} />
                    </ListItemIcon>
                  )}

                  <ListItemText
                    sx={{ cursor: 'pointer' }}
                    primary={file.name}
                    primaryTypographyProps={{ variant: 'body2' }}
                    secondary={
                      <Box sx={{ fontSize: '9px' }}>
                        {!readOnly && bytesToSize(file.size)}
                        {errorOnTypeValidation && (
                          <Typography sx={{ color: theme.palette.error.main }}>
                            This file is not allowed
                          </Typography>
                        )}
                      </Box>
                    }
                    onClick={() => handleOpen(file)}
                  />

                  {!readOnly && (
                    <Tooltip title={'Remove ' + file.name}>
                      <IconButton
                        edge="end"
                        onClick={() => onRemove?.(file)}
                        sx={{ alignSelf: 'baseline' }}
                      >
                        <SvgIcon>
                          <XIcon />
                        </SvgIcon>
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              )
            })}
          </List>{' '}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{selectedFile?.name}</DialogTitle>
            <DialogContent>
              {(selectedFile?.mimeType || selectedFile?.type)?.startsWith(
                'image',
              ) && selectedFilePreview ? (
                <img
                  src={selectedFilePreview}
                  alt={selectedFile?.name}
                  style={{ width: '100%', maxHeight: '600px' }}
                />
              ) : (
                <p>
                  This file can&#39;t be previewed. You may want to{' '}
                  <a href={selectedFile?.path} download>
                    download it
                  </a>
                  .
                </p>
              )}
            </DialogContent>
            <DialogActions>
              <Box
                style={{
                  margin: 'auto',
                }}
              >
                {files?.length > 1 && (
                  <>
                    <IconButton
                      aria-label="previus"
                      onClick={handlePrev}
                      disabled={selectedFileIndex === 0}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                    <IconButton
                      aria-label="next"
                      onClick={handleNext}
                      disabled={selectedFileIndex === files.length - 1}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </DialogActions>

            <Button onClick={handleClose}>Close</Button>
          </Dialog>
        </Box>
      )}
    </>
  )
}

FileList.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
}
