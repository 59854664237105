export function camelCaseToCapitalizedWords(camelCaseStr: string) {
  if (!camelCaseStr) return ''
  // Split the camelCase string into words
  const words = camelCaseStr.replace(/([A-Z])/g, ' $1').split(' ')

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  // Join the words back into a single string
  return capitalizedWords.join(' ')
}
export function kebabCaseToCapitalizedWords(kebabCaseStr: string) {
  if (!kebabCaseStr) return ''

  // Split the kebab-case string into words
  const words = kebabCaseStr.split('-')

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
  })

  // Join the words back into a single string
  return capitalizedWords.join(' ')
}

export const documentTypes = [
  { value: 'Customs Documentation', label: 'Customs Documentation' },
  { value: 'Invoices', label: 'Invoices' },
  { value: 'Packing List', label: 'Packing List' },
  { value: 'DG Declaration', label: 'DG Declaration' },
  { value: 'Safety Data Sheet', label: 'Safety Data Sheet' },
  { value: 'Release/Delivery Order', label: 'Release/Delivery Order' },
  { value: 'Bill Of Materials', label: 'Bill Of Materials' },
  { value: 'Certificate Of Origin', label: 'Certificate Of Origin' },
  { value: 'Certificate Of Conformity', label: 'Certificate Of Conformity' },
  { value: 'Legalized Documentation', label: 'Legalized Documentation' },
  { value: 'Export License/Permit', label: 'Export License/Permit' },
  { value: 'Waiver', label: 'Waiver' },
  { value: 'Letter Of Credit', label: 'Letter Of Credit' },
  { value: 'Bill Of Lading', label: 'Bill Of Lading' },
  { value: 'Air Waybill', label: 'Air Waybill' },
  { value: 'Inspection Certificates', label: 'Inspection Certificates' },
  { value: 'Consignment Notes', label: 'Consignment Notes' },
  { value: 'Booking Confirmation', label: 'Booking Confirmation' },
  { value: 'Purchase Order', label: 'Purchase Order' },
  { value: 'Proof Of Delivery', label: 'Proof Of Delivery' },
  { value: 'Vessel Documents POL', label: 'Vessel Documents POL' },
  { value: 'Vessel Documents POD', label: 'Vessel Documents POD' },
  { value: 'Insurance Certificate', label: 'Insurance Certificate' },
  { value: 'Other', label: 'Other' },
]
