import React from 'react'
import { SeverityPill } from '.'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CloseIcon from '@mui/icons-material/Close'
import TransformIcon from '@mui/icons-material/Transform'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import FlashOffIcon from '@mui/icons-material/FlashOff'
import Inventory2Icon from '@mui/icons-material/Inventory2'
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi'
import WorkspacesIcon from '@mui/icons-material/Workspaces'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import ChatIcon from '@mui/icons-material/Chat'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt'
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import CheckIcon from '@mui/icons-material/Check'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import DoneIcon from '@mui/icons-material/Done'

export type StatusValue =
  | 'New'
  | 'Contacted'
  | 'Qualified'
  | 'Closed Lost'
  | 'Converted'
  | 'Active'
  | 'Inactive'
  | 'Warehousing'
  | 'Transportation'
  | 'Other'
  | 'Proposal'
  | 'Negotiation'
  | 'Closure'
  | 'Pending'
  | 'Rejected'
  | 'Approved'
  | 'Accepted'
  | 'Under review'
  | 'Expired'
  | 'Terminated'
  | 'Draft'
  | 'Finalized'
  | 'Open'
  | 'Closed'
  | 'Re-Opened'
  | 'Final'

export type StatusKey =
  | 'new'
  | 'contacted'
  | 'qualified'
  | 'closed_lost'
  | 'converted'
  | 'active'
  | 'inactive'
  | 'warehousing'
  | 'transportation'
  | 'other'
  | 'proposal'
  | 'negotiation'
  | 'closure'
  | 'pending'
  | 'rejected'
  | 'approved'
  | 'under_review'
  | 'expired'
  | 'terminated'
  | 'draft'
  | 'finalized'
  | 'open'
  | 'closed'
  | 're_opened'
  | 'final'

export const LEAD_STATUS = {
  new: 'New',
  contacted: 'Contacted',
  qualified: 'Qualified',
  closed_lost: 'Closed Lost',
  converted: 'Converted',
}
export const QUOTE_STATUS = {
  new: 'New',
  final: 'Final',
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Accepted',
}
export const QUOTE_STATUS_Customer = {
  final: 'Final',
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Accepted',
}
export const CONTRACT_STATUS = {
  new: 'New',
  under_review: 'Under review',
  active: 'Active',
  rejected: 'Rejected',
  expired: 'Expired',
  terminated: 'Terminated',
}
export const CUSTOMER_STATUS = {
  active: 'Active',
  inactive: 'Inactive',
}
export const STATUS: Record<StatusKey, StatusValue> = {
  new: 'New',
  contacted: 'Contacted',
  qualified: 'Qualified',
  closed_lost: 'Closed Lost',
  converted: 'Converted',
  active: 'Active',
  inactive: 'Inactive',
  warehousing: 'Warehousing',
  transportation: 'Transportation',
  other: 'Other',
  proposal: 'Proposal',
  negotiation: 'Negotiation',
  closure: 'Closure',
  pending: 'Pending',
  rejected: 'Rejected',
  approved: 'Accepted',
  under_review: 'Under review',
  expired: 'Expired',
  terminated: 'Terminated',
  draft: 'Draft',
  finalized: 'Finalized',
  open: 'Open',
  closed: 'Closed',
  re_opened: 'Re-Opened',
  final: 'Final',
}

// Mapping from status to color
const statusColors = {
  new: 'primary',
  contacted: 'info',
  qualified: 'warning',
  closed_lost: 'error',
  converted: 'success',
  proposal: 'info',
  negotiation: 'warning',
  closure: 'error',
  transportation: 'success',
  other: 'warning',
  warehousing: 'info',
  pending: 'info',
  rejected: 'error',
  approved: 'success',
  active: 'primary',
  inactive: 'error',
  under_review: 'warning',
  expired: 'error',
  terminated: 'success',
  draft: 'warning',
  re_opened: 'warning',
  finalized: 'success',
  open: 'success',
  closed: 'error',
  final: 'warning',
}

const iconStyle = { fontSize: '16px', marginRight: '4px' }

const statusIcons: Record<StatusValue, React.ReactElement> = {
  New: <AutoAwesomeIcon style={iconStyle} />,
  Contacted: <PhoneInTalkIcon style={iconStyle} />,
  Qualified: <CheckCircleOutlineIcon style={iconStyle} />,
  'Closed Lost': <CloseIcon style={iconStyle} />, // You can choose CloseIcon or HighlightOffIcon
  Converted: <TransformIcon style={iconStyle} />,
  Active: <FlashOnIcon style={iconStyle} />,
  Inactive: <FlashOffIcon style={iconStyle} />,
  Warehousing: <Inventory2Icon style={iconStyle} />,
  Transportation: <LocalTaxiIcon style={iconStyle} />, // You can choose LocalTaxiIcon or DirectionsCarIcon
  Other: <WorkspacesIcon style={iconStyle} />,
  Proposal: <NoteAddIcon style={iconStyle} />,
  Negotiation: <ChatIcon style={iconStyle} />,
  Closure: <DoneAllIcon style={iconStyle} />,
  Pending: <HourglassEmptyIcon style={iconStyle} />,
  Rejected: <ThumbDownAltIcon style={iconStyle} />,
  Approved: <ThumbUpAltIcon style={iconStyle} />,
  Accepted: <ThumbUpAltIcon style={iconStyle} />,
  'Under review': <FindInPageIcon style={iconStyle} />,
  Expired: <WarningAmberIcon style={iconStyle} />,
  Terminated: <StopCircleIcon style={iconStyle} />,
  Draft: <DriveFileRenameOutlineIcon style={iconStyle} />,
  Finalized: <CheckIcon style={iconStyle} />,
  Open: <LockOpenOutlinedIcon style={iconStyle} />,
  Closed: <LockOutlinedIcon style={iconStyle} />,
  'Re-Opened': <LockOpenIcon style={iconStyle} />,
  Final: <DoneIcon style={iconStyle} />,
}
type colors = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'

interface StatusComponentProps {
  status?: StatusValue
  key?: StatusKey
}

const getKey = (status: StatusValue) => {
  const keys = Object.keys(STATUS) as StatusKey[]
  for (const key of keys) {
    if (STATUS[key] === status) {
      return key
    }
  }
  return undefined
}

export const Status: React.FC<StatusComponentProps> = ({ key, status }) => {
  let checkedKey: StatusKey | undefined = key

  if (!key && status) {
    checkedKey = getKey(status)
  }

  if (key && !status) {
    status = STATUS[key] || undefined
  }
  return (
    <SeverityPill
      color={checkedKey ? (statusColors[checkedKey] as colors) : 'secondary'}
    >
      {status ? statusIcons[status] : null}{' '}
      {!status && key ? STATUS[key] || undefined : status}
    </SeverityPill>
  )
}
