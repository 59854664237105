/* eslint-disable @typescript-eslint/no-explicit-any */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface FileErrors {
  fileId: string
  errors: {
    commodity?: any
    transportation?: any
  }
}

interface FileErrorsState {
  fileErrorsList: FileErrors[]
}

type GetFileErrorsAction = PayloadAction<FileErrors>

const initialState: FileErrorsState = {
  fileErrorsList: [],
}

const reducers = {
  addFileErrors(state: FileErrorsState, action: GetFileErrorsAction) {
    if (
      state.fileErrorsList?.findIndex(
        (x: FileErrors) => x.fileId === action.payload?.fileId,
      ) > -1
    ) {
      state.fileErrorsList = [
        action.payload,
        ...(state.fileErrorsList?.filter(
          (x: FileErrors) => x.fileId !== action.payload?.fileId,
        ) || []),
      ]
    } else {
      state.fileErrorsList = [...state.fileErrorsList, action.payload]
    }
  },
  removeFileErrors(state: FileErrorsState, action: GetFileErrorsAction) {
    state.fileErrorsList = state.fileErrorsList.filter(
      (item) => item.fileId !== action.payload.fileId,
    )
  },
}

export const slice = createSlice({
  name: 'fileErrors',
  initialState,
  reducers,
})

export const { addFileErrors, removeFileErrors } = slice.actions

export const { reducer } = slice
