import { Box, CardContent } from '@mui/material'
import { useFormik } from 'formik'
import { Modal } from 'src/common/components'
import PurchaseOrderForm from './purchases-form'
import { useUpdatePurchaseOrderMutation } from './usePurchasesRequests'
import { useContext } from 'react'
import { ModalContext } from 'src/common/contexts/modal-context'
import compareObjects from 'src/common/utils/compare-objects'
import toast from 'react-hot-toast'
import RoleGuard from 'src/common/guards/role-guard'
import { defaultValues, validationSchema } from './formConfig'
import { QuotesPurchaseOrdersFormProps } from 'src/common/types/Quote'
import Button from 'src/common/components/loadingButton'

const EditModal = ({
  handleClose,
  open,
  initialValues = {},
  quoteId,
}: QuotesPurchaseOrdersFormProps) => {
  const handleModalClose = () => {
    handleClose()
    formik.resetForm()
  }

  const updateMutation = useUpdatePurchaseOrderMutation({
    callback: handleModalClose,
    quoteId: quoteId,
  })

  const formik = useFormik({
    initialValues: initialValues || defaultValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      const submitValuesWithId = {
        ...values,
        vendorId: values?.vendorId?.id,
        id: initialValues?.id,
        quoteId,
        quotePurchaseId: initialValues?.id,
      }
      {
        compareObjects(formik.initialValues, submitValuesWithId, [
          'basePrice',
          'vendorId',
          'description',
        ])
          ? (handleClose(), toast.success('No changes were made'))
          : updateMutation.mutate({
              values: submitValuesWithId,
              setFieldError: formik.setFieldError,
            })
      }
    },
  })

  const modalContext = useContext(ModalContext)
  if (!modalContext) {
    throw new Error(
      'ModalContext is null. Make sure you are rendering inside ModalProvider.',
    )
  }

  return (
    <Modal
      id="edit-purchases-modal"
      title={'Edit Purchase'}
      onClose={handleModalClose}
      open={open}
      footer={
        <Box display="flex" p={1} gap={2}>
          <RoleGuard scopes={['quote.write']}>
            <Button
              id="sales-quotes-purchase-order-modal-edit-save"
              disabled={formik.isSubmitting || updateMutation.isLoading}
              isLoading={updateMutation.isLoading}
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              onClick={formik.submitForm}
              variant="contained"
            >
              Update
            </Button>
          </RoleGuard>
          <Button
            id="sales-quotes-purchase-order-modal-edit-cancel"
            disabled={formik.isSubmitting || updateMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={handleModalClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <CardContent>
        <PurchaseOrderForm formik={formik} />
      </CardContent>
    </Modal>
  )
}

export default EditModal
