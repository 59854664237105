import { Box } from '@mui/material'
import { NumericFormatText } from './form/RenderField'
import { useGetCurrencySymbol } from '../hooks/use-currency-sign'

interface InvoiceComponentProps {
  total: number
  invoiceCurrency: string
  conversionRate: number
}

const TotalWithDifferentCurrency = ({
  total,
  invoiceCurrency,
  conversionRate = 1,
}: InvoiceComponentProps) => {
  const officeData = JSON.parse(localStorage.getItem('activeOffice') || '{}')
  const defaultCurrency = officeData?.currency?.currencySign
  const totalInDefaultCurrency = total * conversionRate
  const currencySymbol = useGetCurrencySymbol(invoiceCurrency)

  return (
    <Box
      width={'100%'}
      display={'flex'}
      alignContent={'right'}
      justifyContent={'right'}
      flexDirection={'column'}
    >
      <NumericFormatText
        value={total}
        prefix={' ' + currencySymbol || ''}
        fixedDecimalScale={true}
      />
      {defaultCurrency !== currencySymbol && (
        <NumericFormatText
          value={totalInDefaultCurrency}
          prefix={' ' + defaultCurrency || ''}
          fixedDecimalScale={true}
          sx={{ color: 'grey' }}
        />
      )}
    </Box>
  )
}

export default TotalWithDifferentCurrency
