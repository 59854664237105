import { useRef, useState, useEffect } from 'react'
// import { Editor } from 'src/common/components/editor/quill-mention'
import { User } from 'src/common/types/User'
import { Box, IconButton } from '@mui/material'
import { Note, NoRepliesNote } from './types'
import { NewNote as EditNote } from './new-note'
import ReplyIcon from '@mui/icons-material/Reply'
import TimeAgo from '../time-ago/time-ago'
import NoteOptionsMenu from './actions'
import { styled } from '@mui/system'
import { UserWithAvatar } from '../user-with-avatar'
import { useTheme } from '@mui/material/styles'
import {
  useCreateReplyMutation,
  useDeleteNoteMutation,
} from 'src/common/hooks/use-notes-api'
import { elementIsVisibleInViewport } from 'src/common/utils/is-visible'
import ConfirmDialog from '../dialogs/ConfirmationDialog'
import { useModal } from 'src/common/hooks/use-modal'
import { toast } from 'react-hot-toast'
import { FileList } from '../editor/file-list'
import { useSettings } from 'src/common/hooks/use-settings'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import RoleGuard from 'src/common/guards/role-guard'
import Button from '../loadingButton'

const NoteBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  padding: theme.spacing(1),
  border: '1px solid',
  borderRadius: theme.spacing(1),
  borderColor: '#eee',
}))

interface NoteProps {
  note: Note
  allowReply?: boolean
  users: User[]
  updateNote: (note: Note | NoRepliesNote) => void
  noteType: string
  objectId: string
  isUpdating: boolean
}

export const NoteComponent = ({
  note,
  updateNote,
  isUpdating = false,
  allowReply,
  users,
  noteType,
  objectId,
}: NoteProps) => {
  const [isReplying, setIsReplying] = useState(false)
  const [modifyingNotes, setModifyingNotes] = useState<string[]>([])
  const replyMutation = useCreateReplyMutation({ objectId: objectId })
  const { open, handleClose, handleOpen } = useModal()
  const theme = useTheme()
  const settings = useSettings()
  const replyBgColor =
    settings.paletteMode === 'light'
      ? theme.palette.grey[200]
      : theme.palette.background.default

  const deleteMutation = useDeleteNoteMutation({
    callback: () => {
      handleClose()
    },
    noteType: noteType,
  })

  const replies: NoRepliesNote[] = note.replies || [] // repliesQuery.data
  const [showAllReplies, setShowAllReplies] = useState(false)
  const maxVisibleReplies = 3
  const remainingReplies = replies.length - maxVisibleReplies
  const visibleReplies = showAllReplies
    ? replies
    : replies.slice(0, maxVisibleReplies)

  const handleToggleReply = () => {
    setIsReplying(!isReplying)
  }
  const editorlRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (isReplying) {
      const isVisible = elementIsVisibleInViewport(editorlRef.current, false)
      if (!isVisible) {
        editorlRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }
      //
    }
  }, [isReplying])
  const onAddReply = (replyNote: Note) => {
    replyMutation.mutate({ noteId: note.id, ...replyNote })
    setIsReplying(false)
    return replyMutation
  }
  const [deleteId, setDeleteId] = useState('')

  const handleToggleModifyNote = (id: string) => {
    const isModifying = modifyingNotes.includes(id)
    setModifyingNotes(
      isModifying
        ? modifyingNotes.filter((x) => x !== id)
        : [...modifyingNotes, id],
    )
  }

  const handleUpdateNote = async (
    updatedNote: Note | NoRepliesNote,
    id: string,
  ) => {
    handleToggleModifyNote(id)
    return updateNote({ ...updatedNote, id })
  }

  //Reminder to change the text editor text color

  const renderNoteOrEditor = (id: string, note: Note | NoRepliesNote) => {
    return modifyingNotes.includes(id) ? (
      <EditNote
        isLoading={isUpdating}
        users={users}
        initialValue={note}
        onNoteCreate={(updatedNote) =>
          handleUpdateNote(updatedNote as Note | NoRepliesNote, id)
        }
        submitText="Save"
        cancelButton="Cancel"
        onCancel={() => handleToggleModifyNote(id)}
        id={id + 'modify'}
        disabled={isUpdating}
      />
    ) : (
      <Box id={id} sx={{ fontSize: '13px', lineHeight: 1.4, marginLeft: 2 }}>
        <div dangerouslySetInnerHTML={{ __html: note?.content || '' }} />

        <FileList
          files={note.attachments || []}
          readOnly
          size="small"
          hideFilePreview={false}
        />
      </Box>
    )
  }

  const handleConfirmDelete = async () => {
    deleteMutation.mutate(deleteId)

    if (deleteMutation.isError) {
      toast.error('An error happened! Note could not be deleted')
    }
  }
  const renderOptionsMenu = (id: string) => (
    <RoleGuard scopes={['note.write']}>
      <NoteOptionsMenu
        onEdit={() => handleToggleModifyNote(id)}
        onDelete={() => {
          handleOpen()
          setDeleteId(id)
        }}
      />
    </RoleGuard>
  )

  const renderReplyButton = (id: string) => {
    if (modifyingNotes.includes(id)) return ''
    return (
      <Box display={'flex'} alignItems={'end'} justifyContent={'flex-end'}>
        <RoleGuard scopes={['note.write']}>
          <Button
            id="notes-reply"
            onClick={handleToggleReply}
            startIcon={<ReplyIcon />}
          >
            Reply
          </Button>
        </RoleGuard>
      </Box>
    )
  }

  const renderReplyIcon = () => (
    <Box display={'flex'} alignItems={'end'} justifyContent={'flex-end'}>
      <IconButton onClick={handleToggleReply}>
        <ReplyIcon />
      </IconButton>
    </Box>
  )
  const renderNoteHeader = (note: Note | NoRepliesNote) => (
    <Box display={'flex'} justifyContent={'space-between'}>
      {note.userCreated ? (
        <UserWithAvatar user={note.userCreated} size="normal" />
      ) : (
        <div />
      )}
      <Box display={'flex'}>
        {note.date && <TimeAgo timestamp={note.date?.toISOString()} />}
        {renderOptionsMenu(note.id)}
      </Box>
    </Box>
  )
  const renderReplyHeader = (note: Note | NoRepliesNote) => (
    <Box display={'flex'} justifyContent={'space-between'}>
      {note.userCreated ? (
        <UserWithAvatar user={note.userCreated} size="small" hideAvatar />
      ) : (
        <div />
      )}
      <Box display={'flex'}>
        {note.date && <TimeAgo timestamp={note.date?.toISOString()} />}
        {renderOptionsMenu(note.id)}
      </Box>
    </Box>
  )

  const mainMessageRef = useRef<HTMLDivElement | null>(null)

  // Scroll back to the main message when explicitly clicking "Collapse"
  const scrollToMainMessage = () => {
    if (mainMessageRef.current) {
      mainMessageRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <NoteBox ref={mainMessageRef}>
      <Box>
        {renderNoteHeader(note)}

        {renderNoteOrEditor(note.id || '', note)}
        {allowReply && renderReplyButton(note.id)}

        <Box>
          {visibleReplies.map((reply: NoRepliesNote) => {
            return (
              <Box key={reply.id} display={'flex'} alignItems={'start'} gap={1}>
                <Box pt={2.5}>
                  {reply.userCreated && (
                    <UserWithAvatar
                      user={reply.userCreated}
                      size="normal"
                      hideName
                    />
                  )}
                </Box>
                <NoteBox
                  sx={{
                    border: 'none',
                    background: replyBgColor,
                    borderRadius: 2,
                    flex: 1,
                  }}
                >
                  {renderReplyHeader(reply)}
                  {renderNoteOrEditor(reply.id || '', reply)}
                  {renderReplyIcon()}
                </NoteBox>
              </Box>
            )
          })}
          <Box display="flex" justifyContent="flex-end">
            {replies.length > maxVisibleReplies && !showAllReplies && (
              <Button
                id="notes-expand"
                onClick={() => {
                  setShowAllReplies(true)
                }}
              >
                <ExpandMoreIcon />
                Show All ({remainingReplies})
              </Button>
            )}

            {replies.length > maxVisibleReplies && showAllReplies && (
              <Button
                id="notes-collapse"
                onClick={() => {
                  setShowAllReplies(false)
                  scrollToMainMessage()
                }}
              >
                <ExpandLessIcon />
                Collapse
              </Button>
            )}
          </Box>
        </Box>

        {allowReply && isReplying && (
          <Box>
            <div ref={editorlRef}></div>
            <EditNote
              id={'reply' + note.id}
              users={users}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onNoteCreate={(reply: any) => onAddReply(reply)}
              submitText="Reply"
              onCancel={() => setIsReplying(false)}
              cancelButton={'Cancel'}
              disabled={!isReplying}
            />
          </Box>
        )}
      </Box>
      <ConfirmDialog
        buttonId="notes-confirmation"
        open={open}
        title="Are you sure you want to delete this note?"
        message="This action is not reversable"
        onCancel={handleClose}
        onConfirm={handleConfirmDelete}
      />
    </NoteBox>
  )
}
