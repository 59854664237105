/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode } from 'react'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import {
  Call as CallIcon,
  Email as EmailIcon,
  PeopleAlt as MeetingIcon,
} from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import TimeAgo from 'src/common/components/time-ago/time-ago'
import { User, UserAvatar } from 'src/common/types/User'
import { UserWithAvatar } from 'src/common/components/user-with-avatar'
import { Avatar, Box, Card, CardContent } from '@mui/material'
import { keyMapping } from 'src/common/utils/historyReadObjects'

interface GetActionNameProperty {
  action: string
}

interface Changes {
  field: string
  newValue: string
  oldValue: string
}

interface Field {
  field: string
  name: string
  component: (item: any) => ReactNode
}

interface CustomTimelineProperty {
  time: string
  action: string
  user: User
  id: string
  isLastItem?: boolean
  changes?: Changes[] | null
  fields?: Field[]
}

type color =
  | 'error'
  | 'inherit'
  | 'grey'
  | 'secondary'
  | 'primary'
  | 'info'
  | 'success'
  | 'warning'

export const GetTimelineIcon = ({ action }: GetActionNameProperty) => {
  let actionIcon = null
  let actionColor: color = 'grey'

  switch (action) {
    case 'creation':
      actionIcon = <AddIcon />
      actionColor = 'success'
      break
    case 'change':
      actionIcon = <EditIcon />
      actionColor = 'grey'
      break
    case 'deletion':
      actionIcon = <DeleteForeverIcon />
      actionColor = 'error'
      break
    case 'call':
      actionColor = 'success'
      actionIcon = <CallIcon />
      break
    case 'email':
      actionIcon = <EmailIcon />
      break
    case 'meeting':
      actionColor = 'primary'
      actionIcon = <MeetingIcon />
      break

    default:
      actionIcon = <ErrorOutlineIcon />
      actionColor = 'grey'
  }
  return (
    <TimelineDot color={actionColor}>
      <Avatar>{actionIcon}</Avatar>
    </TimelineDot>
  )
}

const GetFormattedActionName = ({ action }: GetActionNameProperty) => {
  let actionName = ''

  switch (action) {
    case 'creation':
      actionName = 'Created'
      break
    case 'change':
      actionName = 'Modified'
      break
    case 'deletion':
      actionName = 'Deleted'
      break
    default:
      actionName = 'action not supported'
  }

  return <Typography variant="body1">{actionName}</Typography>
}

interface ChangesDisplayProps {
  changes: Changes[] | null
  fields?: Field[]
}

const ChangesDisplay = ({ changes, fields = [] }: ChangesDisplayProps) => {
  return (
    <div>
      {changes &&
        changes.map((change: Changes, index: number) => {
          const fieldDisplayData = fields.find(
            (item) => item.field === change.field,
          )

          const displayName =
            fieldDisplayData?.name || keyMapping[change.field] || change.field

          const displayOldValue = fieldDisplayData?.component
            ? fieldDisplayData?.component(change.oldValue)
            : change.oldValue

          const displayNewValue = fieldDisplayData?.component
            ? fieldDisplayData?.component(change.newValue)
            : change.newValue

          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                minHeight: '30px',
                alignContent: 'center',
                my: 1,
              }}
            >
              Field
              <Box
                sx={{
                  fontWeight: 'bold',
                }}
                mx={1}
                component={'span'}
              >
                {displayName}
              </Box>
              {/* eslint-disable-next-line no-extra-boolean-cast */}
              has been modified {!!displayOldValue ? `from` : ''}
              <Box component={'span'} mx={1}>
                {displayOldValue || ''}
              </Box>
              to
              <Box component={'span'} mx={1}>
                {displayNewValue || '-'}
              </Box>
            </Box>
          )
        })}
    </div>
  )
}

export const CustomTimelineItem = ({
  time,
  action,
  user,
  id,
  changes = null,
  isLastItem = false,
  fields = [],
}: CustomTimelineProperty) => {
  return (
    <TimelineItem key={id} sx={{ display: 'flex' }}>
      <TimelineSeparator>
        <GetTimelineIcon action={action} />

        {!isLastItem && (
          <TimelineConnector sx={{ bgcolor: 'text.secondary' }} />
        )}
      </TimelineSeparator>
      <TimelineContent>
        <Card variant="outlined">
          <CardContent sx={{ padding: '13px 10px' }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <UserWithAvatar user={user as UserAvatar} size="normal" />
              <TimeAgo timestamp={time} />
            </Box>
            <Typography
              display={'inline-flex'}
              variant="body1"
              color="textSecondary"
              mt={1}
            >
              <GetFormattedActionName action={action} />
            </Typography>
            <ChangesDisplay changes={changes} fields={fields} />
          </CardContent>
        </Card>
      </TimelineContent>
    </TimelineItem>
  )
}
