/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import PropTypes from 'prop-types'
import { FC, useState } from 'react'
import type { DropzoneOptions, FileWithPath } from 'react-dropzone'
import { useModal } from 'src/common/hooks/use-modal'
import { bytesToSize } from '../../utils/bytes-to-size'
import { FileIcon } from '../file-dropzone/file-icon'
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline'
import { formatDate } from 'src/common/utils/date-locale'
import { useGetOfficeDateFormat } from 'src/common/hooks/use-get-office-date-format'

export interface FilePreview {
  name: string
  path?: string
  url?: string
  mimeType?: string
  hash?: string
  size: number
  type?: string
  tootlip?: string
}

export type File = FileWithPath

interface FileDropzoneProps extends DropzoneOptions {
  size?: 'small' | 'normal'
  files?: any
  onRemove?: (file: FileWithPath) => void
  readOnly?: boolean
  hideFilePreview?: boolean
  allowedFileTypes?: string[]
  maxFileSize?: number
  gridConfig?: object
  downloadWithoutPreview?: boolean
}

const truncateText = (text: string, length: number) => {
  if (text.length > length) {
    return text.substring(0, length - 3) + '...'
  }
  return text
}

export const FileList: FC<FileDropzoneProps> = (props) => {
  const {
    size = 'normal',
    files = [],
    onRemove,
    readOnly = false,
    hideFilePreview = true,
    allowedFileTypes,
    maxFileSize,
    gridConfig,
    downloadWithoutPreview = false,
  } = props
  const theme = useTheme()

  const { dateFormat } = useGetOfficeDateFormat()

  const hasAnyFiles = files.length > 0
  const [selectedFilePreview, setSelectedFilePreview] = useState<string | null>(
    null,
  )

  const [selectedFile, setSelectedFile] = useState<FilePreview | null>(null)

  const {
    handleOpen: handleOpenModal,
    handleClose: handleCloseModal,
    open,
  } = useModal()

  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null,
  )

  const handleNext = () => {
    if (selectedFileIndex !== null && selectedFileIndex < files.length - 1) {
      handleOpen(files[selectedFileIndex + 1])
    }
  }

  const handlePrev = () => {
    if (selectedFileIndex !== null && selectedFileIndex > 0) {
      handleOpen(files[selectedFileIndex - 1])
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOpen = (file: any) => {
    setSelectedFileIndex(files.indexOf(file))
    if (!file.mimeType && file.type) {
      //local file
      const reader = new FileReader()
      reader.onloadend = () => {
        setSelectedFilePreview(reader.result as string)
      }
      reader.readAsDataURL(file)
    } else {
      setSelectedFilePreview(file.path || file?.url)
    }
    setSelectedFile(file)
    if (downloadWithoutPreview) return
    handleOpenModal()
  }

  const handleClose = () => {
    handleCloseModal()
  }

  return (
    <>
      {hasAnyFiles && (
        <Box>
          <List
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Grid container spacing={1} paddingTop={3} width="100%">
              {files.map((file: any, index: number) => {
                const extension = file.name.split('.').pop()
                let errorOnTypeValidation = false
                if (!readOnly) {
                  errorOnTypeValidation =
                    !!allowedFileTypes && !allowedFileTypes?.includes(file.type)
                }
                return (
                  <Grid
                    item
                    {...(gridConfig
                      ? gridConfig
                      : { xs: 12, sm: 6, md: 4, lg: 3 })}
                    key={file.path || index}
                  >
                    <ListItem
                      sx={{
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 1,
                        '& + &': {
                          mt: 1,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <FileIcon extension={extension} />
                      </ListItemIcon>
                      {!hideFilePreview && (
                        <ListItemIcon
                          sx={{ height: '18px' }}
                          onClick={() => handleOpen(file)}
                        >
                          <FileIcon extension={extension} />
                        </ListItemIcon>
                      )}

                      <ListItemText
                        sx={{
                          cursor: 'pointer',
                        }}
                        primary={
                          <Tooltip
                            title={file.tootlip || file.name}
                            placement="bottom-start"
                          >
                            <Box
                              textOverflow="ellipsis"
                              whiteSpace="nowrap"
                              overflow="hidden"
                            >
                              {downloadWithoutPreview ? (
                                <a
                                  href={selectedFile?.path || selectedFile?.url}
                                  download
                                >
                                  {file.name}
                                </a>
                              ) : (
                                file.name
                              )}
                            </Box>
                          </Tooltip>
                        }
                        primaryTypographyProps={{ variant: 'body2' }}
                        secondary={
                          <span style={{ fontSize: '9px' }}>
                            {file.created_at &&
                              formatDate(file.created_at, dateFormat)}
                            {errorOnTypeValidation && (
                              <span style={{ color: theme.palette.error.main }}>
                                This file is not allowed
                              </span>
                            )}
                          </span>
                        }
                        onClick={() => handleOpen(file)}
                      />

                      {!readOnly && (
                        <Tooltip title={'Remove ' + file.name}>
                          <IconButton
                            edge="end"
                            onClick={() => onRemove?.(file)}
                            sx={{ alignSelf: 'baseline' }}
                          >
                            <SvgIcon>
                              <XIcon />
                            </SvgIcon>
                          </IconButton>
                        </Tooltip>
                      )}
                    </ListItem>
                  </Grid>
                )
              })}
            </Grid>
          </List>{' '}
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{selectedFile?.name}</DialogTitle>
            <DialogContent>
              {(selectedFile?.mimeType || selectedFile?.type)?.startsWith(
                'image',
              ) && selectedFilePreview ? (
                <img
                  src={selectedFilePreview}
                  alt={selectedFile?.name}
                  style={{ width: '100%', maxHeight: '500px' }}
                />
              ) : (
                <p>
                  This file can&#39;t be previewed. You may want to{' '}
                  <a href={selectedFile?.path || selectedFile?.url} download>
                    download it
                  </a>
                  .
                </p>
              )}
            </DialogContent>
            <DialogActions>
              <Box
                style={{
                  margin: 'auto',
                }}
              >
                {files?.length > 1 && (
                  <>
                    <IconButton
                      aria-label="previus"
                      onClick={handlePrev}
                      disabled={selectedFileIndex === 0}
                    >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                    <IconButton
                      aria-label="next"
                      onClick={handleNext}
                      disabled={selectedFileIndex === files.length - 1}
                    >
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </>
                )}
              </Box>
            </DialogActions>

            <Button onClick={handleClose}>Close</Button>
          </Dialog>
        </Box>
      )}
    </>
  )
}

FileList.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
}
