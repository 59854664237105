/* eslint-disable react-hooks/exhaustive-deps */
import { Box, CardContent } from '@mui/material'
import { useFormik } from 'formik'
import { matchIsValidTel } from 'mui-tel-input'
import { Modal } from 'src/common/components'
import * as Yup from 'yup'
import ContactForm from '../contact/form/contactForm'
import { useCreateContactMutation } from './useContactRequests'
import { cloneDeep, debounce } from 'lodash'
import { useGetContactsByEmail } from 'src/common/apis'
import Button from 'src/common/components/loadingButton'
import { useCallback, useEffect, useState } from 'react'

type ContactModalProps = {
  handleClose: () => void
  refetchFn?: () => void
  submitCallback?: (data: unknown) => void
  open: boolean
}

interface Values {
  name: string
  phone: string
  email: string
  customerId?: string
  submit: null
}

const defaultValues: Values = {
  name: '',
  phone: '',
  email: '',
  customerId: '',
  submit: null,
}

const CreateModal = ({
  handleClose,
  open,
  submitCallback,
}: ContactModalProps) => {
  const createMutation = useCreateContactMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback: (data: any) => {
      const newItem = cloneDeep({ ...formik.values, ...data })
      submitCallback && submitCallback(newItem)
      handleClose()
      formik.resetForm()
    },
  })

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required'),
      phone: Yup.string()
        .test(
          'phone',
          'Phone number is invalid',
          (value) => !!matchIsValidTel(value || ''),
        )
        .required('Phone number is required'),

      email: Yup.string()
        .email('Must be a valid email')
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          'Invalid email address',
        )
        .max(255)
        .required('Email is required'),
      // .test('unique-email', 'Email already in use', async () => {
      //   try {
      //     const response = await emailCheckQuery.refetch()

      //     if (response.data) {
      //       // If email exists, return false
      //       return false
      //     }
      //     return true
      //   } catch (error) {
      //     console.error('Error checking email uniqueness', error)
      //     return true // Optional: decide how to handle API errors
      //   }
      // }),
    }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSubmit: async (values: any): Promise<void> => {
      const submitValues = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        customerId: values.customerId?.id,
      }

      await createMutation.mutate({
        values: submitValues,
        setFieldError: formik.setFieldError,
      })
    },
  })

  const [emailValue, setEmailValue] = useState()

  const debouncedUpdate = useCallback(
    debounce((value) => {
      setEmailValue(value)
    }, 1000),
    [],
  )
  useEffect(() => {
    // Cleanup debounced function to prevent memory leaks or unnecessary calls
    return () => {
      debouncedUpdate.cancel()
    }
  }, [debouncedUpdate])

  useGetContactsByEmail({ email: emailValue })

  const handleModalClose = () => {
    formik.resetForm()
    handleClose()
  }

  return (
    <Modal
      id="create-contact-modal"
      title={'Create new contact'}
      onClose={handleModalClose}
      open={open}
      footer={
        <Box display="flex" p={1} gap={2}>
          <Button
            id="crm-contacts-modal-save"
            disabled={formik.isSubmitting || createMutation.isLoading}
            isLoading={createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={formik.submitForm}
            variant="contained"
          >
            Save
          </Button>
          <Button
            id="crm-contacts-modal-cancel"
            disabled={formik.isSubmitting || createMutation.isLoading}
            fullWidth
            size="large"
            sx={{ mt: 2 }}
            onClick={handleModalClose}
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      }
    >
      <CardContent>
        <ContactForm formik={formik} onEmailChange={debouncedUpdate} />
      </CardContent>
    </Modal>
  )
}

export default CreateModal
