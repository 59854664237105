/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Chip, InputAdornment, Stack, TextField } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { EmailInputWithChipsTypes } from 'src/common/types/mail'

export const EmailInputWithChips: FC<EmailInputWithChipsTypes> = ({
  formik,
  name,
  label,
  disabled,
}) => {
  const [chips, setChips] = useState<string[]>([])

  useEffect(() => {
    if (formik.initialValues[name]) {
      const emails = formik.initialValues[name].trim().split(',')
      setChips(emails)
      formik.setFieldValue(name, formik.initialValues[name].trim())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validateInput = (event: any) => {
    if (
      (event.key === 'Tab' || event.key === 'Enter' || event.type === 'blur') &&
      event.target.value
    ) {
      event.preventDefault()
      const invalidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
        event.target.value,
      )

      if (!invalidEmail) {
        formik.setFieldError(name, 'Please enter a valid email address.')
        formik.setFieldTouched(name, true, false)
        return
      }

      setChips([...chips, event.target.value])
      formik.setFieldValue(name, [...chips, event.target.value].join(','))
      event.target.value = ''
    } else if (event.target.value === '') {
      formik.setFieldError(name, '')
    }
  }

  const handleDelete = (chipToDelete: string) => () => {
    const emails = chips.filter((chip) => chip !== chipToDelete)
    setChips(emails)
    formik.setFieldValue(name, emails.join(','))
  }

  return (
    <Box width="100%" paddingX={0.2} paddingY={0.5}>
      <TextField
        variant="standard"
        type="email"
        fullWidth
        id={name}
        name={name}
        onKeyDown={validateInput}
        onBlur={validateInput}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <Stack pl={1}>
              <InputAdornment position="start">
                <Box
                  color={
                    formik.touched[name] && Boolean(formik.errors[name])
                      ? '#F04438'
                      : ''
                  }
                  fontSize="14px"
                >
                  {label}
                </Box>
                <Box ml={chips.length > 0 ? 1 : 0}>
                  {chips.map((chip: any, index: number) => (
                    <Chip
                      key={index}
                      label={chip}
                      onDelete={handleDelete(chip)}
                      size="small"
                      sx={{ mr: 0.2 }}
                    />
                  ))}
                </Box>
              </InputAdornment>
            </Stack>
          ),
        }}
      />
    </Box>
  )
}
