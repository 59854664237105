/* eslint-disable @typescript-eslint/no-non-null-assertion */
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { useState, type FC, type ReactNode } from 'react'
import RoleGuard from 'src/common/guards/role-guard'
import { SideNavItem } from './side-nav-item'
import { useAuth } from 'src/common/hooks/use-auth'

interface Item {
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
  scope?: string[]
}

const renderItems = ({
  depth = 0,
  items,
  pathname,
}: {
  depth?: number
  items: Item[]
  pathname?: string | null
}): JSX.Element[] =>
  items.reduce(
    (acc: JSX.Element[], item) =>
      reduceChildRoutes({
        acc,
        depth,
        item,
        pathname,
      }),
    [],
  )

const reduceChildRoutes = ({
  acc,
  depth,
  item,
  pathname,
}: {
  acc: JSX.Element[]
  depth: number
  item: Item
  pathname?: string | null
}): Array<JSX.Element> => {
  const checkPath = !!(item.path && pathname)
  const partialMatch = checkPath ? pathname.includes(item.path!) : false
  const exactMatch = checkPath ? pathname === item.path?.split('?')[0] : false

  if (item.items) {
    acc.push(
      <SideNavItem
        active={partialMatch}
        depth={depth}
        disabled={item.disabled}
        icon={item.icon}
        key={item.title + pathname}
        label={item.label}
        open={partialMatch}
        title={item.title}
        id={item.title}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: 'none',
            m: 0,
            p: 0,
          }}
        >
          {renderItems({
            depth: depth + 1,
            items: item.items,
            pathname,
          })}
        </Stack>
      </SideNavItem>,
    )
  } else {
    acc.push(
      <RoleGuard scopes={[...(item?.scope || [])]}>
        <SideNavItem
          active={exactMatch}
          depth={depth}
          disabled={item.disabled}
          external={item.external}
          icon={item.icon}
          key={item.title}
          label={item.label}
          path={item.path}
          title={item.title}
          id={item.title}
        />
      </RoleGuard>,
    )
  }

  return acc
}

interface SideNavSectionProps {
  items?: Item[]
  pathname?: string | null
  subheader?: string
  isDrawerOpen?: boolean
  collapse?: boolean
}

export const SideNavSection: FC<SideNavSectionProps> = (props) => {
  const {
    items = [],
    pathname,
    subheader = '',
    isDrawerOpen,
    collapse,
    ...other
  } = props
  const [open, setOpen] = useState(false)
  const { user } = useAuth()

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <Stack
      component="ul"
      spacing={0.5}
      sx={{
        listStyle: 'none',
        m: 0,
        p: 0,
      }}
      {...other}
    >
      {isDrawerOpen && subheader && !user?.customerId && (
        <Box
          component="li"
          sx={{
            color: 'var(--nav-section-title-color)',
            fontSize: 12,
            fontWeight: 700,
            lineHeight: 1.66,
            mb: 1,
            ml: 1,
            textTransform: 'uppercase',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: collapse ? 'pointer' : 'normal',
          }}
          onClick={
            collapse
              ? handleClick
              : () => {
                  return
                }
          }
        >
          {subheader}
          {collapse ? open ? <ExpandLess /> : <ExpandMore /> : null}
        </Box>
      )}

      {!collapse
        ? renderItems({ items, pathname })
        : open
        ? renderItems({ items, pathname })
        : null}
    </Stack>
  )
}

SideNavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  subheader: PropTypes.string,
}
