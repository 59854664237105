/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react'
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SvgIcon,
  Tooltip,
} from '@mui/material'
import { FileIcon } from '../file-dropzone/file-icon'
import { bytesToSize } from '../../utils/bytes-to-size'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import { MailAttachmentsProps } from 'src/common/types/mail'

export const MailAttachments: FC<MailAttachmentsProps> = ({
  attachments = [],
  setAttachments,
}) => {
  const count = attachments.length

  const onRemove = (attachment: File) => {
    setAttachments((prev: File[]) =>
      prev.filter((item) => item.name !== attachment.name),
    )
  }
  return (
    <Box>
      <ListSubheader sx={{ lineHeight: '35px' }}>
        {count} Attachments
      </ListSubheader>
      <Grid container px={0.5} columnGap={0}>
        {attachments.map((attachment) => (
          <Grid item xs={3} key={attachment.name}>
            <List dense sx={{ bgcolor: 'background.paper', p: 0 }}>
              <ListItem role={undefined} dense>
                <ListItemIcon style={{ marginRight: 7 }}>
                  <FileIcon
                    extension={attachment.name.split('.').pop()}
                    width="25px"
                  />
                </ListItemIcon>

                <ListItemText
                  id={attachment.name}
                  primary={
                    <Tooltip title={attachment.name} placement="bottom-start">
                      {attachment.name as any}
                    </Tooltip>
                  }
                  primaryTypographyProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  secondary={
                    <span style={{ fontSize: '9px' }}>
                      {bytesToSize(attachment.size)}
                    </span>
                  }
                />
                <Tooltip title={'Remove ' + attachment.name}>
                  <IconButton
                    edge="end"
                    onClick={() => onRemove(attachment)}
                    sx={{ alignSelf: 'baseline' }}
                  >
                    <SvgIcon>
                      <XIcon />
                    </SvgIcon>
                  </IconButton>
                </Tooltip>
              </ListItem>
            </List>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
