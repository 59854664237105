import { useState, useCallback, useEffect } from 'react'
import { Editor } from 'src/common/components/editor/quill-mention'
import { User, UserMention } from 'src/common/types/User'
import { File } from 'src/common/types/File'
import { Box, Typography } from '@mui/material'
import Quill from 'quill'
import { Note, NoRepliesNote } from './types'
import RoleGuard from 'src/common/guards/role-guard'
import Button from '../loadingButton'

interface NewNote {
  attachments: File[]
  mentionedUsers: UserMention[]
  content: string
  id?: string
}

interface NoteProps {
  users: User[]
  onNoteCreate: ({
    attachments,
    mentionedUsers,
    content,
  }: Partial<Note> | Partial<NoRepliesNote>) => void
  id?: string
  submitText?: string | 'Note' | 'Reply'
  initialValue?: NewNote | Note
  cancelButton?: string | null
  onCancel?: () => void
  disabled?: boolean
  isLoading?: boolean
}

export const NewNote = ({
  users = [],
  onNoteCreate,
  id,
  submitText = 'Add note',
  initialValue,
  cancelButton,
  onCancel,
  disabled = false,
  isLoading,
}: NoteProps) => {
  const [comment, setNote] = useState(initialValue?.content || '')
  const [attachments, setAttachments] = useState<File[]>(
    initialValue?.attachments || [],
  )
  const [mentionedUsers, setMentionedUsers] = useState<UserMention[]>(
    initialValue?.mentionedUsers || [],
  )
  const [error, setError] = useState('')
  const [errorWithFiles, setErrorWithFiles] = useState(false)
  const [quillInstance, setQuillInstance] = useState<Quill | null>(null)

  const handleEditorRef = (instance: Quill | null) => {
    setQuillInstance(instance)
  }

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const regex = /(<([^>]+)>)/gi
    const hasText = !!comment?.replace(regex, '').trim().length

    if ((!comment || !hasText) && !attachments.length) {
      setError('Note content cannot be empty!')
      return
    } else {
      setError('')
    }

    if (errorWithFiles) {
      setError('fix files with problems!')
      return
    }

    if (onNoteCreate) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any = await onNoteCreate({
        attachments,
        mentionedUsers,
        content: comment,
      })

      if (result.isError) {
        setError('Note could not be added!')
        return
      }
      setError('')

      if (!result.isError) {
        setNote('')
        setAttachments([])
        setMentionedUsers([])

        if (quillInstance) {
          quillInstance.setText('')
          quillInstance.deleteText(0, 1)
        }
      }
    }
  }

  // after defining your states
  useEffect(() => {
    if (!comment && quillInstance) {
      quillInstance.setText('')
    }
  }, [comment, quillInstance])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpload = useCallback((attachments: any): void => {
    attachments &&
      setAttachments((prevFiles) => {
        return [...prevFiles, ...attachments]
      })
  }, [])

  const handleMention = (users: UserMention[]) => {
    setMentionedUsers(users)
  }

  const handleRemove = useCallback((file: File): void => {
    setAttachments((prevFiles) => {
      return prevFiles.filter((_file) => _file.name !== file.name)
    })
  }, [])

  const handleEditorChange = (text = '') => {
    setNote(text)
  }

  const userList: UserMention[] = users.map((x: User) => ({
    id: x.id || '',
    value: x.firstName + ' ' + x.lastName,
  }))

  return (
    <form>
      <RoleGuard scopes={['note.write']}>
        <Editor
          id={id || 'new-editor'}
          users={userList}
          onMention={handleMention}
          attachments={attachments}
          onUpload={onUpload}
          multipleFiles
          onChange={handleEditorChange}
          value={comment}
          handleRemoveFile={handleRemove}
          readOnly={false}
          editorRef={handleEditorRef}
          setError={(e: boolean) => {
            setErrorWithFiles(!!e)
          }}
        />
      </RoleGuard>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}
        gap={1}
      >
        {error && (
          <Typography variant="body2" color={'error'}>
            {error}
          </Typography>
        )}

        <RoleGuard scopes={['note.write']}>
          {cancelButton && (
            <Button
              size="small"
              variant="outlined"
              onClick={onCancel}
              disabled={disabled || isLoading}
            >
              {cancelButton}
            </Button>
          )}
          <Button
            isLoading={isLoading}
            size="small"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            disabled={disabled}
          >
            {submitText}
          </Button>
        </RoleGuard>
      </Box>
    </form>
  )
}
