/* eslint-disable @typescript-eslint/no-explicit-any */
import Timeline from '@mui/lab/Timeline'
import { useQuery } from '@tanstack/react-query'
import { get } from 'src/common/utils/api'
import LinearProgress from '@mui/material/LinearProgress'
import { CustomTimelineItem } from './timeline-item'
import { ReactNode } from 'react'
import { timelineItemClasses } from '@mui/lab'
import { useGetUsers } from 'src/common/apis'
interface Field {
  field: string
  name: string
  component: (item: any) => ReactNode
}
interface TimelineProps {
  objectId: string
  fields?: Field[]
}
export default function HistoryTimeline({
  objectId,
  fields = [],
}: TimelineProps) {
  const users = useGetUsers({
    convertToOptions: false,
  })
  const usersList = users?.data || []

  const useGetHistoryValues = () => {
    return useQuery(['history', objectId], {
      queryFn: () => get('history/' + objectId),
      enabled: !!objectId,
      select: (data: any) => data.data,
    })
  }

  const historyQuery = useGetHistoryValues()

  const isLoading = historyQuery.isLoading || historyQuery.isFetching

  const history = historyQuery?.data || []

  return (
    <>
      {isLoading && <LinearProgress color="secondary" />}
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {history.map(
          ({ occurredAt, action, madeBy, id, changes }: any, index: number) => {
            const staticUser = usersList.find((x: any) => x.id === madeBy) || {
              id: madeBy,
              firstName: 'Test',
              lastName: ' Best',
            }

            return (
              <CustomTimelineItem
                time={occurredAt}
                action={action}
                user={staticUser}
                changes={changes}
                id={id}
                key={id}
                isLastItem={index === history.length - 1}
                fields={fields}
              />
            )
          },
        )}
      </Timeline>
    </>
  )
}
