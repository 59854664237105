import * as React from 'react'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { Box } from '@mui/material'

export default function CommentSkeleton() {
  return (
    <Stack spacing={1} width={'100%'}>
      {/* For variant="text", adjust the height via font-size */}
      {/* <Skeleton variant="text" sx={{ fontSize: '1rem' }} /> */}
      <Box display={'flex'} gap={2}>
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant="text" width={100} sx={{ fontSize: '1rem' }} />
      </Box>
      {/* For other variants, adjust the size with `width` and `height` */}

      <Skeleton variant="rectangular" height={120} />
      <Box
        gap={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ marginLeft: '16px', paddingLeft: '16px' }}
      >
        <Skeleton variant="rounded" height={60} />
        <Skeleton variant="rounded" height={60} />
      </Box>
      <Skeleton variant="rectangular" height={120} />
    </Stack>
  )
}
