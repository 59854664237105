import SunEditor from 'suneditor-react'
import InputLabel from '@mui/material/InputLabel'
import 'suneditor/dist/css/suneditor.min.css'
import './sun-editor.css'
import { FormControl } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'

const editorOptions = {
  height: '100px',
  strictMode: false,
  mode: 'classic',
  rtl: false,
  hideToolbar: true,
  resizingBar: false,
  showPathLabel: false,
  resizeEnable: false,
  imageResizing: false,
  imageHeightShow: false,
  imageAlignShow: false,
  imageFileInput: false,
  imageUrlInput: false,
  videoResizing: false,
  videoHeightShow: false,
  videoAlignShow: false,
  videoFileInput: false,
  videoUrlInput: false,
  videoRatioShow: false,
  audioUrlInput: false,
  tabDisable: false,
  shortcutsHint: false,
  mediaAutoSelect: false,
  pasteTagsWhitelist:
    'table, tbody, thead, tr, td, br, b, i, u, strong, em, ul, ol, li',
  buttonList: [[]],
}

const SimpleEditor = ({
  onChangeHandler,
  defaultValue,
  options,
  label,
  variant,
  readOnly,
  name,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const [body, setBody] = useState<string>()

  useEffect(() => {
    setBody(defaultValue || '')
  }, [defaultValue])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeHandler = (content: string) => {
    setBody(content)
    onChangeHandler(content)
  }

  const theme = useTheme()

  return (
    <FormControl
      sx={{
        width: '100%',
        mt: '1px',
        border: `1px solid ${
          theme.palette.mode === 'dark'
            ? theme.palette.neutral[700]
            : theme.palette.background.paper
        }`,
        borderRadius: '4px',
      }}
      variant={variant as 'standard' | 'outlined' | 'filled' | 'standard'}
    >
      <InputLabel
        htmlFor={name}
        shrink={true}
        sx={{
          borderRadius: '10px',
          background: theme?.palette?.background.paper,
          padding: '0 4px 0 4px',
        }}
      >
        {label}
      </InputLabel>

      <SunEditor
        setOptions={options || editorOptions}
        onChange={changeHandler}
        defaultValue={defaultValue}
        readOnly={readOnly}
        setContents={body}
      />
    </FormControl>
  )
}
export default SimpleEditor
