import { getInitials } from 'src/common/utils/get-initials'
import { styled } from '@mui/system'
import { Avatar, Box, Typography } from '@mui/material'
import { UserAvatar } from 'src/common/types/User'

interface Avatar {
  user: UserAvatar
  hideName?: boolean
  hideAvatar?: boolean
  size?: 'small' | 'normal'
}

const StyledAvatar = styled(Avatar)({
  height: 32,
  width: 32,
})
export const UserWithAvatar = ({
  user,
  hideAvatar = false,
  hideName = false,
  size = 'small',
}: Avatar) => {
  return (
    <Box
      display="flex"
      gap={0.5}
      justifyContent={'center'}
      alignItems={'center'}
    >
      {!hideAvatar && (
        <StyledAvatar
          sx={{
            width: size === 'small' ? 16 : 28,
            height: size === 'small' ? 16 : 28,
            fontSize: size === 'small' ? 8 : 14,
          }}
        >
          {getInitials(user.fullName || user.firstName + ' ' + user.lastName)}
        </StyledAvatar>
      )}
      {!hideName && (
        <Typography variant="body2">
          {user.fullName || user.firstName + ' ' + user.lastName}
        </Typography>
      )}
    </Box>
  )
}
