import { useEffect, useState, useCallback } from 'react'
import Quill, { Sources, Delta } from 'quill'
import { FileList } from './file-list'
import 'quill-mention'
import 'quill-emoji/dist/quill-emoji.js'
import { UserMention } from 'src/common/types/User'
import { FileWithPath } from 'react-dropzone'

interface EditorParam {
  id: string
  users: UserMention[]
  onMention?: (user: UserMention[]) => void
  attachments?: FileWithPath[]
  onUpload?: (files: FileWithPath[] | FileList | File[]) => void
  multipleFiles?: boolean
  onChange?: (text?: string) => void
  value?: string
  readOnly?: boolean
  handleRemoveFile?: (file: FileWithPath) => void
  editorRef?: (obj: Quill | null) => void
  maxFileSize?: number
  setError?: (e: boolean) => void
}

const attachmentSVG =
  '<svg height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 280.067 280.067" xml:space="preserve" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"/><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#2e2b28;" d="M149.823,257.142c-31.398,30.698-81.882,30.576-113.105-0.429 c-31.214-30.987-31.337-81.129-0.42-112.308l-0.026-0.018L149.841,31.615l14.203-14.098c23.522-23.356,61.65-23.356,85.172,0 s23.522,61.221,0,84.586l-125.19,123.02l-0.044-0.035c-15.428,14.771-40.018,14.666-55.262-0.394 c-15.244-15.069-15.34-39.361-0.394-54.588l-0.044-0.053l13.94-13.756l69.701-68.843l13.931,13.774l-83.632,82.599 c-7.701,7.596-7.701,19.926,0,27.53s20.188,7.604,27.88,0L235.02,87.987l-0.035-0.026l0.473-0.403 c15.682-15.568,15.682-40.823,0-56.39s-41.094-15.568-56.776,0l-0.42,0.473l-0.026-0.018l-14.194,14.089L50.466,158.485 c-23.522,23.356-23.522,61.221,0,84.577s61.659,23.356,85.163,0l99.375-98.675l14.194-14.089l14.194,14.089l-14.194,14.098 l-99.357,98.675C149.841,257.159,149.823,257.142,149.823,257.142z"/> </g> </g></svg>'

const extractMentions = (quill: Quill) => {
  const contents = quill.getContents()
  const mentions: UserMention[] = []
  contents.ops?.forEach((op) => {
    if (op.insert && typeof op.insert === 'object') {
      const mention = op.insert.mention
      if (mention) {
        mentions.push(mention)
      }
    }
  })
  return mentions
}

const handleDeleteMention = (
  quill: Quill,
  setMentionedUsers: (mentions: UserMention[]) => void,
) => {
  return (delta: Delta, oldContents: Delta, source: Sources) => {
    if (source === 'user') {
      const extractedMentions = extractMentions(quill)
      setMentionedUsers(extractedMentions)
    }
  }
}

const getEditorContent = (quill: Quill, onChange?: (html: string) => void) => {
  const html = quill.root.innerHTML
  onChange && onChange(html) // this logs the HTML content of the editor.
}

const selectLocalImage = (
  onUpload?: (files: FileList) => void,
  multipleFiles = true,
) => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  multipleFiles && input.setAttribute('multiple', 'true')
  input.click()

  // Listen upload local image and send to server
  input.onchange = async () => {
    onUpload && input.files && onUpload(input.files)
  }
}

export const Editor = ({
  id = 'default-editor',
  users = [],
  onMention,
  attachments = [],
  onUpload,
  multipleFiles = true,
  value,
  onChange,
  readOnly = false,
  handleRemoveFile,
  editorRef,
  setError,
  maxFileSize,
}: EditorParam) => {
  const [mentionedUsers, setMentionedUsers] = useState<UserMention[]>([])
  // const [quillInstance, setQuillInstance] = useState<Quill | null>(null)

  const initializeQuill = useCallback(() => {
    const quillNode = document.getElementById('#editor' + id)
    const existingQuill = quillNode && Quill.find(quillNode)
    let quill: Quill
    if (existingQuill) {
      // use the existing quill instance
      quill = existingQuill
    } else {
      quill = new Quill('#editor' + id, {
        modules: {
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            source: async function (searchTerm: string, renderList: any) {
              const matchedPeople = await suggestPeople(searchTerm)
              renderList(matchedPeople)
            },
            renderItem: renderItem,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onSelect: (item: any, insertItem: any) => {
              insertItem(item)
              setMentionedUsers((prevMentions) => [...prevMentions, item])
            },
          },
          'emoji-toolbar': true,
          // 'emoji-textarea': true,
          // 'emoji-shortname': true,
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              ['emoji'],
              ['attachment'],
            ],
            handlers: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              attachment: function (_itms: unknown) {
                // console.log('todo handle inline attachments', itms)
              }, // Empty handler to be filled later
            },
          },
        },
        theme: 'snow',
      })
    }
    // setQuillInstance(quill)

    if (editorRef) {
      editorRef(quill)
    }

    if (value) {
      quill.clipboard.dangerouslyPasteHTML(value)
    }
    if (readOnly) {
      quill.enable(false)
    }
    const editorElement = document.getElementById('editor' + id)

    // Create a new mutation observer
    const observer = new MutationObserver((mutations) => {
      // Look through all mutations that just occured
      for (const mutation of mutations) {
        // If the addedNodes property has one or more nodes
        if (mutation.addedNodes.length && !readOnly) {
          const attachementButton = document?.querySelector('.ql-attachment')
          if (attachementButton) {
            attachementButton.innerHTML = attachmentSVG
            observer.disconnect()
          }
        }
      }
    })

    const toolbar = quill.getModule('toolbar')

    // Check if the element is not null before observing
    if (editorElement) {
      // Start observing the document with the configured parameters
      observer.observe(editorElement, { childList: true, subtree: true })
      if (!readOnly) {
        // Wait a bit to make sure the editor is fully initialized
        setTimeout(() => {
          const parentElement = editorElement.parentElement

          const attachementButton =
            parentElement && parentElement.querySelector('.ql-attachment')

          if (attachementButton) {
            attachementButton.innerHTML = attachmentSVG

            observer.disconnect()
          }
          // Add your handler for the attachment button
          toolbar.addHandler('attachment', () =>
            selectLocalImage(onUpload, multipleFiles),
          )
        }, 10)
      }
    }
    // Add your handler for the attachment button
    toolbar.addHandler('attachment', () =>
      selectLocalImage(onUpload, multipleFiles),
    )

    // Add the file handler
    quill.getModule('toolbar').addHandler('attachment', selectLocalImage)

    quill.on('text-change', handleDeleteMention(quill, setMentionedUsers))

    quill.on('text-change', () => getEditorContent(quill, onChange))

    // toolbar.addHandler('attachment', () =>
    //   selectLocalImage(onUpload, multipleFiles),
    // )

    return () => {
      quill.off('text-change', handleDeleteMention(quill, setMentionedUsers))
    }

    // function insertToEditor(url: any) { // to put inine images
    //   // push image url to rich editor.
    //   const range = quill.getSelection()
    //   range && quill.insertEmbed(range.index, 'image', url)
    //   console.log(url)
    // }

    // return () => {
    //   quill.off('text-change', handleDeleteMention)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users])

  useEffect(() => {
    onMention && onMention(mentionedUsers)
  }, [mentionedUsers, onMention])

  async function suggestPeople(searchTerm: string) {
    const matchedPeople = users.filter((person) => {
      return person.value?.toLowerCase().includes(searchTerm?.toLowerCase())
    })
    return matchedPeople
  }

  const renderItem = (
    item: UserMention,
    searchTerm: string | RegExp,
    id: string | null,
    selected: boolean,
  ) => {
    const highlightedItem = item.value.replaceAll(
      searchTerm,
      `<mark>${searchTerm}</mark>`,
    )
    const className = `mention-item${selected ? ' mention-item-selected' : ''}`

    return `
    <div id="${id}" class="${className}" tabindex="1">
      ${highlightedItem}
    </div>
  `
  }

  useEffect(() => {
    initializeQuill()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div id={id} className={readOnly ? 'read-only' : 'wrapper' + id}>
        <div id={'editor' + id} />
      </div>
      <FileList
        files={attachments || []}
        readOnly={readOnly}
        size="small"
        onRemove={handleRemoveFile}
        allowedFileTypes={[
          'image/jpeg',
          'image/png',
          'application/pdf',
          'text/plain',
          'application/msword',
        ]}
        setError={setError}
        maxFileSize={maxFileSize}
      />
    </>
  )
}
