import { Stack } from '@mui/material'
import GooglePlacesAutocomplete from 'src/common/components/autocomplete/places'
import RenderField from 'src/common/components/form/RenderField'

interface FormProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
}

const VendorForm = ({ formik }: FormProps) => {
  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Stack spacing={3}>
        <RenderField
          formik={formik}
          field={{
            label: 'Company name *',
            name: 'companyName',
            type: 'text',
          }}
        />
        <GooglePlacesAutocomplete
          formik={formik}
          name="fulladdress"
          displayFields={{
            place: false,
            city: true,
            street: true,
            country: true,
            zipCode: true,
          }}
        />

        <RenderField
          formik={formik}
          field={{
            label: 'Tax ID ',
            name: 'taxId',
            type: 'text',
          }}
        />
        <RenderField
          formik={formik}
          field={{
            label: 'Client number *',
            name: 'customerNo',
            type: 'text',
          }}
        />
        <RenderField
          formik={formik}
          field={{
            label: 'Payment terms *',
            name: 'paymentTerms',
            type: 'number',
          }}
          suffix="days"
        />
      </Stack>
    </form>
  )
}

export default VendorForm
