import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import type { FC } from 'react'
import { MailPreviewModalProps } from 'src/common/types/mail'
import { FileList } from '../file-dropzone/file-list-preview'
import { MailBodyText } from './mail-body-text'
import { MailPreviewToolbar } from './mail-preview-toolbar'

export const MailPreviewModal: FC<MailPreviewModalProps> = ({
  sender,
  to,
  cc,
  bcc,
  subject,
  body,
  attachments,
  selectedExistingDocuments,
  createdAt,
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Stack
        sx={{
          flexGrow: 1,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <DialogTitle>
          <MailPreviewToolbar
            createdAt={createdAt}
            sender={sender}
            to={to}
            cc={cc}
            bcc={bcc}
          />
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
          >
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">{subject}</Typography>
          </Box>
        </DialogTitle>

        <DialogContent dividers>
          <Box
            sx={{
              flexGrow: 1,
              pr: 3,
              py: 1,
            }}
          >
            <Stack sx={{ mt: 2 }} spacing={6}>
              <MailBodyText message={body} />
            </Stack>

            <FileList
              files={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                selectedExistingDocuments?.map((f: any) => {
                  if (f instanceof File) {
                    return {
                      name: f.name,
                      mimeType: f.type,
                      size: f.size,
                      destinationPath: f,
                      hash: f,
                      path: URL.createObjectURL(f),
                    }
                  } else {
                    return f
                  }
                }) || []
              }
              readOnly
              gridConfig={{ xs: 6 }}
              downloadWithoutPreview
            />

            <FileList
              files={
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                attachments?.map((f: any) => {
                  if (f instanceof File) {
                    return {
                      name: f.name,
                      mimeType: f.type,
                      size: f.size,
                      destinationPath: f,
                      hash: f,
                      path: URL.createObjectURL(f),
                    }
                  } else {
                    return f
                  }
                }) || []
              }
              readOnly
              gridConfig={{ xs: 6 }}
              downloadWithoutPreview
            />
          </Box>
        </DialogContent>
      </Stack>
    </Dialog>
  )
}
