import * as Yup from 'yup'

export type VendorsModalProps = {
  handleClose: () => void
  refetchFn?: () => void
  open: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any
  submitCallback?: (item: unknown) => void
}

export interface Vendor {
  id?: string
  value?: string
  label?: string
  customerNo: string
  name?: string
  companyName: string
  street: string
  zipCode: string
  country: string
  countryCode: string
  city: string
  taxId: string
  paymentTerms?: number
  submit: null
}
export interface Values {
  id?: string
  customerNo: string
  companyName: string
  street: string
  zipCode: string
  country: string
  countryCode: string
  city: string
  taxId: string
  paymentTerms?: number
  submit: null
}

export const defaultValues: Values = {
  customerNo: '',
  companyName: '',
  street: '',
  zipCode: '',
  country: '',
  countryCode: '',
  city: '',
  taxId: '',
  submit: null,
}

export const validationSchema = Yup.object({
  customerNo: Yup.string().max(255).required('Customer number is required'),
  companyName: Yup.string().max(255).required('Company name is required'),
  street: Yup.string().max(255).required('Street is required'),
  zipCode: Yup.string().max(255).required('Zip code is required'),
  country: Yup.string().max(255).required('Country is required'),
  city: Yup.string().max(255).required('City is required'),
  taxId: Yup.string().max(255).nullable(),
  paymentTerms: Yup.number().required('Payment terms is required'),
})
