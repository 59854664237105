import type { FC } from 'react'
import { styled } from '@mui/material/styles'
import { MailBodyTextProps } from 'src/common/types/mail'

const MarkdownWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.body1.fontFamily,
  '& table': {
    display: 'table',
    border: '1px solid #ccc',
    width: '100%',
    maxWidth: '100%',
    margin: '0 0 10px',
    backgroundColor: 'transparent',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    borderColor: 'black !important',
    tableLayout: 'fixed !important',
  },
  '& table td': {
    border: '1px solid black',
    padding: '0.4em',
    backgroundClip: 'padding-box',
  },
}))

export const MailBodyText: FC<MailBodyTextProps> = (props) => {
  const { message = '' } = props

  return (
    <MarkdownWrapper>
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </MarkdownWrapper>
  )
}
