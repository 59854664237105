import { Box, Stack, Typography } from '@mui/material'
import type { FC } from 'react'
import { MailPreviewToolbarProps } from 'src/common/types/mail'

export const MailPreviewToolbar: FC<MailPreviewToolbarProps> = (props) => {
  const { sender, to, cc, bcc, createdAt } = props

  const EmailList = ({ label, emails }: { label: string; emails: string }) => (
    <Box display="flex" alignItems="center" flexDirection={'row'}>
      <Typography color="text.secondary" variant="subtitle1">
        {label}:&nbsp;
      </Typography>
      <Typography color="text.primary" variant="subtitle1">
        {emails?.split(',').map((email, index) => (
          <Box component="span" mr={0.5} key={email}>
            {email}
            {emails?.split(',').length === index + 1 ? '' : ','}
          </Box>
        ))}
      </Typography>
    </Box>
  )

  return (
    <div>
      <Stack alignItems="center" direction="row" spacing={2} sx={{ p: 3 }}>
        <div>
          {sender && (
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Typography color="text.secondary" variant="subtitle1">
                Sender:&nbsp;
              </Typography>
              <Typography color="text.primary" variant="subtitle1">
                {sender}
              </Typography>
            </Box>
          )}
          {to && <EmailList label="To" emails={to} />}
          {cc && <EmailList label="CC" emails={cc} />}
          {bcc && <EmailList label="BCC" emails={bcc} />}

          {createdAt && (
            <Box display="flex" alignItems="center" flexDirection={'row'}>
              <Typography color="text.secondary" variant="subtitle1">
                Created At:&nbsp;
              </Typography>
              <Typography color="text.primary" variant="subtitle1">
                {createdAt}
              </Typography>
            </Box>
          )}
        </div>
      </Stack>
    </div>
  )
}
