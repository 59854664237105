/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useState } from 'react'
import { ComposerState } from '../types/mail'

const useComposer = () => {
  const initialState: ComposerState = {
    isFullScreen: false,
    isOpen: false,
  }

  const [state, setState] = useState<ComposerState>(initialState)

  const handleOpen = useCallback((): void => {
    setState((prevState: any) => ({
      ...prevState,
      isOpen: true,
    }))
  }, [])

  const handleClose = useCallback(
    (): void => {
      setState(initialState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const handleMaximize = useCallback((): void => {
    setState((prevState: any) => ({
      ...prevState,
      isFullScreen: true,
    }))
  }, [])

  const handleMinimize = useCallback((): void => {
    setState((prevState: any) => ({
      ...prevState,
      isFullScreen: false,
    }))
  }, [])

  return {
    ...state,
    handleClose,
    handleMaximize,
    handleMinimize,
    handleOpen,
  }
}
export default useComposer
