import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { INDEXES } from 'src/common/apis/constants'
import { post, put, postDelete, get } from 'src/common/utils/api'
import { invalidateQueriesWithDelay } from 'src/common/utils/wait'

interface SubmitValues {
  id?: string
  customerNo: string
  companyName: string
  street: string
  zipCode: string
  country: string
  countryCode: string
  city: string
  taxId: string
  paymentTerms?: number
}

interface mutationProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (data?: any) => void
}

export const useGetVendorDetails = (id: string) => {
  return useQuery({
    enabled: !!id,
    queryKey: [INDEXES.VENDOR, id],
    queryFn: () => get(`common/vendors/${id}`),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    select: (data: any) => {
      return {
        ...data,
        companyName: data.name,
      }
    },
  })
}

export const useCreateVendorMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => post('common/vendors', values, setFieldError),
    onSuccess: (data, variables) => {
      const callbackProps = { id: data?.data?.id, ...variables.values }
      toast.success(`Vendor was created successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.VENDOR])
      callback && callback(callbackProps)
    },
    onError: () => {
      toast.error(`Vendors could not be created! Please try again`)
    },
  })
}

export const useUpdateVendorMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => put(`common/vendors/${values.id}`, values, setFieldError),
    onSuccess: (_data, variables) => {
      toast.success(`Success! Vendor was updated`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.VENDOR])
      invalidateQueriesWithDelay(queryClient, [
        INDEXES.VENDOR,
        variables?.values?.id,
      ])
      callback && callback()
    },
    onError: () => {
      toast.error(`vendors could not be updated! Please try again`)
    },
  })
}

export const useDeleteVendorMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (vendorId: string) => postDelete(`common/vendors/${vendorId}`),
    onSuccess: () => {
      toast.success(`Vendor was deleted successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.VENDOR])
      callback && callback()
    },
    onError: () => {
      toast.error(`Vendor could not be deleted! Please try again`)
      callback && callback()
    },
  })
}
