/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { post, get, put, postFile, postDelete } from 'src/common/utils/api'
import {
  OperationsMutationProp as mutationProp,
  OperationsFilesInvoicesFinaliseMutation as finaliseMutationProps,
} from 'src/common/types/Operation'
import { invalidateQueriesWithDelay } from 'src/common/utils/wait'
import { INDEXES } from 'src/common/apis/constants'
import { useState } from 'react'
import { useAuth } from 'src/common/hooks/use-auth'

export const useGetInvoices = () => {
  return useQuery(['invoices'], {
    queryFn: () => get(`finance/invoices/`),
    retry: false,
    // select: (data) => {
    //   return {
    //     ...data,
    //     lineItems: Object.values(data.invoiceItems || {}),
    //     contactId: data.contact
    //       ? {
    //           ...data.contact,
    //           label: data.contact?.name,
    //           value: data.contact?.id,
    //         }
    //       : null,
    //     customer: data.customer
    //       ? {
    //           ...data.customer,
    //           label: data.customer?.name,
    //           value: data.customer?.id,
    //         }
    //       : null,
    //     businessEntityId: data.businessEntity?.id
    //       ? {
    //           ...data.businessEntity,
    //           value: data.businessEntity?.id,
    //           label: data.businessEntity?.companyName,
    //         }
    //       : null,
    //   }
    // },
  })
}
export const useGetCustomerInvoices = (customerId: string) => {
  return useQuery(['invoices'], {
    queryFn: () => get(`finance/${customerId}/invoices/`),
    retry: false,
  })
}
export const useGetInvoiceDetails = (id: string | null) => {
  return useQuery(['invoice', id], {
    queryFn: () => get(`finance/invoices/${id}`),
    enabled: !!id,
    retry: false,
    select: (data) => {
      return {
        ...data,
        lineItems: Object.values(data.invoiceItems || {}),
        contactId: data.contact ? data?.contact?.id : null,
        customer: data.customer
          ? {
              ...data.customer,
              label: data.customer?.name,
              value: data.customer?.id,
            }
          : null,
        businessEntityId: data.businessEntity?.id
          ? {
              ...data.businessEntity,
              value: data.businessEntity?.id,
              label: data.businessEntity?.companyName,
            }
          : null,
      }
    },
  })
}

export const useGetInvoicePreviewDetails = (id: string | null) => {
  const { user } = useAuth()
  const isCustomer = !!user?.customerId

  return useQuery([isCustomer ? 'customer/invoice' : 'invoice', id], {
    queryFn: () =>
      isCustomer
        ? get(`customers/${user?.customerId}/invoices/${id}`)
        : get(`finance/invoices/${id}`),
    enabled: !!id,
    retry: false,
    select: (data) => {
      return {
        ...data,
        contactId: data.contact ? data.contact?.name : null,
        customer: data.customer,
        businessEntity: data.businessEntity,
      }
    },
  })
}

export const useCreateInvoiceMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return post('finance/invoices', values, setFieldError)
    },

    onSuccess: () => {
      toast.success(`Invoice was created successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.INVOICE], 700)
      callback && callback()
    },
    onError: () => {
      toast.error(`Invoice could not be created! Please try again`)
    },
  })
}

export const useBatchPrintMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()
  const [isLoadingGeneral, setIsLoadingComplete] = useState(false)

  return useMutation({
    mutationFn: ({ values }: { values: string[] }) => {
      toast.loading(
        'Your PDF is being prepared... Please remain on this page until the process is complete!',
      )

      const activeOfficeId = localStorage.getItem('activeOfficeId')
      const today = new Date()
      const formattedDate = `${String(today.getDate()).padStart(
        2,
        '0',
      )}-${String(today.getMonth() + 1).padStart(
        2,
        '0',
      )}-${today.getFullYear()}`
      return postFile(
        'finance/invoices/bulk-print',
        {
          officeId: activeOfficeId,
          invoiceIds: values,
        },
        'bulk-print-invoices-' + formattedDate,
      )
    },

    onMutate: () => {
      setIsLoadingComplete(true)
    },
    onSuccess: async (data, variables) => {
      callback && callback()

      await setTimeout(() => {
        try {
          toast.success(`Invoices print is ready!`)
          invalidateQueriesWithDelay(queryClient, [INDEXES.INVOICE], 700)
          callback && callback()
        } catch {
          toast.dismiss()
          toast.error(`Invoices could not be printed! Please try again`)
        } finally {
          setIsLoadingComplete(false)
          toast.dismiss()
        }
      }, 1000)
    },
    onError: () => {
      toast.dismiss()
      toast.error(`Invoices could not be printed! Please try again`)
      setIsLoadingComplete(false)
    },
  })
}

export const useUpdateInvoiceMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      values: any
      setFieldError?: (field: string, value: string | undefined) => void
    }) => {
      return put(`finance/invoices/${values.id}`, values, setFieldError)
    },

    onSuccess: () => {
      toast.success(`Invoice was updated successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.INVOICE], 700)
      callback && callback()
    },
    onError: () => {
      toast.error(`Invoice could not be updated! Please try again`)
    },
  })
}

export const useFinaliseInvoiceMutation = ({
  callback,
  id,
}: finaliseMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: () => {
      return post(`finance/invoices/${id}/finalize`)
    },

    onSuccess: () => {
      toast.success(`Invoice was confirmed successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.INVOICE], 700)
      callback && callback()
    },
    onError: () => {
      toast.error(`Invoice could not be confirmed! Please try again`)
    },
  })
}

export const useGenerateInvoicePDFMutation = ({
  callback,
  id,
}: finaliseMutationProps) => {
  const queryClient = useQueryClient()

  return useMutation({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mutationFn: () => {
      return get(`finance/invoices/${id}/generate-pdf`)
    },

    onSuccess: () => {
      // toast.success(`Invoice pdf was created successfully`)
      invalidateQueriesWithDelay(queryClient, ['invoice', id], 700)
      callback && callback()
    },
    onError: () => {
      // toast.error(`Invoice pdf could not be created! Please try again`)
    },
  })
}

export const useGetInvoiceOfFile = (id: string | null) => {
  return useQuery(['file', id], {
    queryFn: () => {
      return get(`finance/invoices/${id}"`)
    },
    enabled: !id,
  })
}

export const useDeleteInvoiceMutation = ({ callback }: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (invoiceId: string) =>
      postDelete(`finance/invoices/${invoiceId}`),
    onSuccess: () => {
      toast.success(`Invoice was deleted successfully`)
      invalidateQueriesWithDelay(queryClient, [INDEXES.INVOICE], 700)
      callback && callback()
    },
    onError: () => {
      toast.error(`Invoice could not be deleted! Please try again`)
    },
  })
}
