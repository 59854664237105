/* eslint-disable react-hooks/exhaustive-deps */
import CreateContactsModal from 'src/modules/contact/create-modal'
import { Option } from '../grid/types'
import StaticAutocomplete from './common/autocomplete-with-create'
import { useGetContactsWithCustomerId } from 'src/common/apis'
import { useCallback } from 'react'
import { get } from 'lodash'

interface ContactsAutocompleteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: any
  readOnly?: boolean
  multiple?: boolean
  isLoading?: boolean
  name?: string
  label?: string
  placeholder?: string
  allowCreate?: boolean
  size?: 'small' | 'medium'
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterFilter?: (item: any) => any | null
  filterByCustomerId: string
  hideNullCustomerIds?: boolean | undefined
  contactsOfCustomer?: Option[]
}

const StaticContactsAutocomplete = ({
  name = 'contact',
  label = 'Contact',
  formik,
  readOnly,
  multiple = false,
  placeholder,
  isLoading,
  filterByCustomerId,
  size = 'medium',
  allowCreate,
}: ContactsAutocompleteProps) => {
  const contactsQuery = useGetContactsWithCustomerId(filterByCustomerId)
  const value = get(formik.values, name)
  const error = get(formik.errors, name)
  const touched = get(formik.touched, name)

  const renderInput = useCallback(() => {
    return (
      <StaticAutocomplete
        formik={formik}
        options={contactsQuery.data || []}
        isLoading={contactsQuery.isLoading || isLoading}
        readOnly={readOnly}
        name={name}
        label={label || 'Contact'}
        size={size}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onModalSubmit={(newItem: any) => {
          // contactsQuery.refetch()
          return {
            ...newItem,
            id: newItem.id,
            value: newItem.id,
            label: newItem.name,
          }
        }}
        allowCreate={allowCreate}
        ModalComponent={CreateContactsModal}
        multiple={multiple}
        placeholder={placeholder}
        variant="outlined"
      />
    )
  }, [
    readOnly,
    value,
    error,
    touched,
    filterByCustomerId,
    contactsQuery.isLoading,
    contactsQuery.isFetching,
  ])

  return renderInput()
}

export default StaticContactsAutocomplete
