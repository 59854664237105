/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import Expand01Icon from '@untitled-ui/icons-react/build/esm/Expand01'
import Minimize01Icon from '@untitled-ui/icons-react/build/esm/Minimize01'
import XIcon from '@untitled-ui/icons-react/build/esm/X'
import {
  Backdrop,
  Box,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Portal,
  Stack,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import SunEditor from 'suneditor-react'
import { FileDropzoneMinimal } from '../file-dropzone/file-dropzone-minimal'
import { EmailInputWithChips } from './email-input-with-chips'
import { FileIcon } from '../file-dropzone/file-icon'
import { MailAttachments } from './mail-attachments'
import { useModal } from 'src/common/hooks/use-modal'
import { MailPreviewModal } from './mail-preview-modal'
import { MailComposerProps } from 'src/common/types/mail'
import 'suneditor/dist/css/suneditor.min.css'
import '../sun-editor.css'
import { Bucket } from 'src/common/types/Operation'
import { camelCaseToCapitalizedWords } from 'src/modules/files/tabs/utils'
import { formatDate } from 'src/common/utils/date-locale'
import Button from '../loadingButton'
import { useGetOfficeDateFormat } from 'src/common/hooks/use-get-office-date-format'

const editorOptions = {
  height: '400px',
  showPathLabel: false,
  buttonList: [
    ['bold', 'underline', 'italic', 'font', 'fontSize'],
    ['fontColor', 'hiliteColor'],
    ['align', 'horizontalRule', 'list'],
    ['table', 'link'],
    ['undo', 'redo'],
    ['removeFormat'],
  ],
  fontSize: [12, 14, 16, 18, 20],
  font: [
    'Sans Serif',
    'Arial',
    'Courier',
    'Garamond',
    'Georgia',
    'Impact',
    'Tahoma',
    'Times New Roman',
    'Trebuchet MS',
  ],
  colorList: [
    '#828282',
    '#FF5400',
    '#676464',
    '#F1F2F4',
    '#FF9B00',
    '#F00',
    '#fa6e30',
    '#000',
    'rgba(255, 153, 0, 0.1)',
    '#FF6600',
    '#0099FF',
    '#74CC6D',
    '#FF9900',
    '#CCCCCC',
  ],
}

const mailPreviewFilesMapper = (
  buckets: any,
  selectedFiles: {
    [key: string]: {
      id: string
      name: string
    }
  },
  attacheFiles: File[],
) => {
  const files = []

  buckets?.map((bucket: Bucket) => {
    const { documents } = bucket
    {
      documents?.map((f: any) => {
        if (selectedFiles[f.id]) {
          files.push(f)
        }
      })
    }
  })
  files.push(...attacheFiles)

  return files
}

export const MailComposer: FC<MailComposerProps> = ({
  title,
  formik,
  maximize = false,
  open = false,
  files,
  bodyPreFilled,
  preAttachments,
  canAttachFiles = true,
  onClose,
  onMaximize,
  onMinimize,
  sendMail,
  isLoading,
  fieldsDisplay,
  hideDocumentCheckbox,
}) => {
  const { dateFormat } = useGetOfficeDateFormat()

  const [body, setBody] = useState<string>()
  const [shimpnetDocuments, setShimpnetDocuments] = useState<{
    [key: string]: {
      id: string
      name: string
    }
  }>({})
  const [attachments, setAttachments] = useState<File[]>([])

  const {
    open: openModal,
    handleOpen,
    handleClose: handleModalClose,
  } = useModal()

  useEffect(() => {
    setBody(bodyPreFilled || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleShipmentDocumentClick = (document: any) => {
    if (shimpnetDocuments[document.id]) {
      const { [document.id]: _, ...rest } = shimpnetDocuments
      setShimpnetDocuments({ ...rest })
    } else
      setShimpnetDocuments((prev) => ({
        ...prev,
        [document.id]: document.id,
      }))
  }

  const onChangeHandler = (content: string) => {
    setBody(content)
  }

  const handleSubmitForm = async () => {
    sendMail?.({
      body,
      attachments: Object.values(shimpnetDocuments).join(','),
      attachedFiles: attachments,
    })
    formik.handleSubmit({})
  }

  const handleFileUpload = async (file: File) => {
    setAttachments((prev) => [...prev, file])
  }

  const handleOnComposerClose = () => {
    onClose?.()
    setAttachments([])
    setShimpnetDocuments({})
    setBody('')
    formik.resetForm()
  }

  if (!open) {
    return null
  }

  return (
    <Portal>
      <div>
        <form noValidate onSubmit={formik.handleSubmit}>
          <Backdrop open={maximize} />
          <Paper
            sx={{
              bottom: 0,
              display: 'flex',
              flexDirection: 'column',
              margin: 3,
              maxHeight: (theme) => `calc(100% - ${theme.spacing(12)})`,
              maxWidth: (theme) => `calc(100% - ${theme.spacing(6)})`,
              minHeight: 720,
              outline: 'none',
              position: 'fixed',
              overflowY: 'scroll',
              overflowX: 'hidden',
              right: 0,
              width: 720,
              zIndex: 1200,
              ...(maximize && {
                borderRadius: 0,
                height: '100%',
                margin: 0,
                maxHeight: '100%',
                maxWidth: '100%',
                width: '100%',
              }),
            }}
            elevation={12}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                px: 2,
                py: 1,
              }}
            >
              <Typography variant="h6">{title || 'New Message'}</Typography>
              <Box sx={{ flexGrow: 1 }} />
              {maximize ? (
                <IconButton onClick={onMinimize}>
                  <SvgIcon>
                    <Minimize01Icon />
                  </SvgIcon>
                </IconButton>
              ) : (
                <IconButton onClick={onMaximize}>
                  <SvgIcon>
                    <Expand01Icon />
                  </SvgIcon>
                </IconButton>
              )}
              <IconButton onClick={handleOnComposerClose}>
                <SvgIcon>
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            {fieldsDisplay?.to?.hide ? (
              ''
            ) : (
              <EmailInputWithChips
                formik={formik}
                name="to"
                label="To:"
                disabled={fieldsDisplay?.to?.disabled}
              />
            )}
            {fieldsDisplay?.cc?.hide ? (
              ''
            ) : (
              <EmailInputWithChips
                formik={formik}
                name="cc"
                label="CC:"
                disabled={fieldsDisplay?.cc?.disabled}
              />
            )}
            {fieldsDisplay?.bcc?.hide ? (
              ''
            ) : (
              <EmailInputWithChips
                formik={formik}
                name="bcc"
                label="BCC:"
                disabled={fieldsDisplay?.bcc?.disabled}
              />
            )}
            {fieldsDisplay?.subject?.hide ? (
              ''
            ) : (
              <Box width="100%" paddingX={0.2} paddingY={0.5}>
                <TextField
                  variant="standard"
                  fullWidth
                  id="subject"
                  name="subject"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.subject && Boolean(formik.errors.subject)
                  }
                  helperText={formik.touched.subject && formik.errors.subject}
                  disabled={fieldsDisplay?.subject?.disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Box
                          sx={{
                            paddingLeft: 1,
                            fontSize: '14px',
                            color:
                              formik.touched.subject &&
                              Boolean(formik.errors.subject)
                                ? 'red'
                                : '',
                          }}
                        >
                          Subject:
                        </Box>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            <Box py={2}>
              {fieldsDisplay?.body?.hide ? (
                ''
              ) : (
                <SunEditor
                  setOptions={editorOptions}
                  onChange={onChangeHandler}
                  defaultValue={bodyPreFilled}
                  disable={fieldsDisplay?.body?.disabled}
                />
              )}
            </Box>
            <Grid container columnGap={0}>
              {files?.map((bucket: Bucket) => {
                const { documents } = bucket

                if (documents && documents.length) {
                  return (
                    <Grid item xs={3} key={bucket.name}>
                      <List
                        dense
                        subheader={
                          <ListSubheader
                            sx={{ width: '100%', textAlign: 'center' }}
                          >
                            {camelCaseToCapitalizedWords(bucket.name)}
                          </ListSubheader>
                        }
                        sx={{ bgcolor: 'background.paper' }}
                      >
                        {documents?.map((f: any) => (
                          <ListItemButton
                            role={undefined}
                            onClick={() => handleShipmentDocumentClick(f)}
                            disableGutters
                            key={bucket.name + f.name}
                            disableRipple
                            sx={{ px: 0.5 }}
                          >
                            {hideDocumentCheckbox ? (
                              <></>
                            ) : (
                              <Checkbox
                                checked={!!shimpnetDocuments[f.id]}
                                tabIndex={0}
                                disableRipple
                                style={{ padding: '0 4px 0 0' }}
                              />
                            )}
                            <ListItemIcon style={{ marginRight: 7 }}>
                              <FileIcon
                                extension={f?.name?.split('.')?.pop()}
                                width="25px"
                              />
                            </ListItemIcon>

                            <ListItemText
                              id={f?.name}
                              primary={
                                <Tooltip
                                  title={f?.name}
                                  placement="bottom-start"
                                >
                                  {f?.name}
                                </Tooltip>
                              }
                              primaryTypographyProps={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                              secondary={
                                <span style={{ fontSize: '9px' }}>
                                  {f.created_at &&
                                    formatDate(f.created_at, dateFormat)}
                                </span>
                              }
                            />
                          </ListItemButton>
                        ))}
                      </List>
                    </Grid>
                  )
                }
              })}
            </Grid>
            <Box
              height="100vh"
              display="flex"
              justifyContent="flex-end"
              flexDirection="column"
            >
              <Divider />
              {canAttachFiles && (
                <MailAttachments
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              )}
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width={'100%'}
                spacing={3}
                sx={{ p: 2 }}
              >
                {preAttachments && preAttachments?.length > 0 ? (
                  <div></div>
                ) : (
                  <Tooltip title="Attach file">
                    <IconButton size="small">
                      <FileDropzoneMinimal
                        onUpload={(files) => {
                          if (files.length > 0) {
                            files.forEach((file) => {
                              handleFileUpload(file)
                            })
                          } else {
                            handleFileUpload(files[0])
                          }
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                <Stack direction="row" alignItems="center" gap={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleOpen}
                  >
                    Preview
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleSubmitForm}
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Send
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Paper>
        </form>
        <MailPreviewModal
          open={openModal}
          onClose={handleModalClose}
          to={formik.values.to}
          cc={formik.values.cc}
          bcc={formik.values.bcc}
          subject={formik.values.subject}
          body={body}
          attachments={mailPreviewFilesMapper(
            files,
            shimpnetDocuments,
            attachments,
          )}
        />
      </div>
    </Portal>
  )
}
