import { Tooltip } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

export const OverflowTip = ({ children }: { children?: React.ReactNode }) => {
  const [isOverflowed, setIsOverflow] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const textElementRef = useRef<any>()
  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth,
    )
  }, [])
  return (
    <Tooltip
      title={children}
      disableHoverListener={!isOverflowed}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -14],
              },
            },
          ],
        },
      }}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '6px 16px',
        }}
      >
        {children}
      </div>
    </Tooltip>
  )
}
