/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { MailComposer } from 'src/common/components/mails/mail-composer'
import { Quote } from 'src/common/types/Quote'
import { useAuth } from 'src/common/hooks/use-auth'
import { uniq } from 'lodash'

const QuoteEmail = ({
  quotes,
  handleSendEmailMutation,
  composer,
}: {
  quotes: Quote[]
  handleSendEmailMutation: any
  composer: any
}) => {
  const validationSchema = Yup.object().shape({
    to: Yup.string().required('Reciver email is required'),
  })
  const { user } = useAuth()

  const emails = quotes.map((x) => x.contact?.email || '')
  const contactNames = uniq(quotes.map((x) => x.contact?.name || ''))
  console.log(quotes)
  const subject =
    quotes.length > 1
      ? `Quotes ${quotes
          .map((q) => q?.reference)
          .join(', ')} are ready for review`
      : `Quote ${
          quotes?.length ? quotes[0]?.reference : ''
        } is ready for review`

  const bodyPreFilled = `<p>Good day,</p><p>${contactNames.join(', ')} <br></p>
  <p>We hope you are well! Thank you for your inquiry. Rulewave is dedicated to finding the best solution for your request and is happy to share your quote is ready and can be viewed in our Rulewave EVO Customer Portal.</p>
    <b>How to Access Your Quote:</b>
    <ol>
        <li>Log in to the EVO Customer Portal.</li>
        <li>Navigate to the Quotes Module to view your most recent quote.</li>
        <li>From there, you can see who issued the quote and either accept or reject it with just a few clicks.</li>
    </ol>
    <p>If needed, you can also export the quote as a PDF for your records or to share with your team. We have also attached the quote in PDF to the email for your convenience. You can also simply approve or reject your quote via email. In that case, reach out to your Rulewave contact who created the quote. They will take care of the rest for you!</p>
    <p>Best regards,</p>
    <p><strong>${user?.firstName + ' ' + user?.lastName}</strong></p>
    <p>Rulewave BV<br>
    Phone: +31 - (0) 88-0605040</p> </p>`

  const formik = useFormik({
    initialValues: {
      quoteIds: quotes.map((x) => x.id),
      to: uniq(emails).join(',') || '',
      cc: user?.email,
      subject: subject,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values): Promise<void> => {
      console.log(values, 'Submited Values')
    },
  })

  const handeleFormSubmit = (prop: { body: string; attachments: string }) => {
    handleSendEmailMutation.mutate({
      values: {
        ...formik.values,
        body: prop.body,
        to: formik.values.to?.split(','),
        cc: formik.values.cc?.split(','),
      },
      setFieldError: formik.setFieldError,
    })
    composer.handleClose()
  }
  return quotes?.length === 0 ? (
    <></>
  ) : (
    <>
      {composer.isOpen && (
        <MailComposer
          formik={formik}
          maximize={composer.isFullScreen}
          onClose={composer.handleClose}
          onMaximize={composer.handleMaximize}
          onMinimize={composer.handleMinimize}
          open={composer.isOpen}
          sendMail={handeleFormSubmit}
          bodyPreFilled={bodyPreFilled}
          canAttachFiles={false}
          preAttachments={['']}
          isLoading={handleSendEmailMutation?.isLoading}
          files={quotes.map((q) => ({
            documents: [{ name: q.reference + '.pdf', url: q.pdfUrl }],
          }))}
          fieldsDisplay={{
            to: { disabled: quotes.length < 2 },
            cc: { disabled: false },
            bcc: { hide: true, disabled: true },
          }}
          title="Quote email"
          hideDocumentCheckbox
        />
      )}
      {/* <MailPreviewModal
        open={openModal}
        onClose={handleModalClose}
        {...modalPreviewData}
      /> */}
    </>
  )
}

export default QuoteEmail
