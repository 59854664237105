import { useGetCurrencyValues } from '../apis'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetCurrencySymbol = (currencyCode: any) => {
  const currencyQuery = useGetCurrencyValues()

  if (currencyQuery.isLoading) {
    return currencyCode?.currencySign || currencyCode
  }
  const currency = currencyQuery?.data?.find(
    (item: { code: string }) => item.code === currencyCode,
  )

  return currency
    ? currency.sign || currency.currencySign
    : currencyCode?.currencySign || currencyCode
}
