import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { parseISO } from 'date-fns'
import { toast } from 'react-hot-toast'
import {
  get,
  postDelete,
  postFormData,
  putFormData,
} from 'src/common/utils/api'

import { DO_Note, NewNote, Note, Reply } from '../components/notes/types'
import { UserMention } from '../types/User'
import { uniq } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transformToFormData = (values: any) => {
  const data = new FormData()
  values &&
    Object.keys(values).forEach((key) => {
      const value = values[key as keyof Note]
      if (value) {
        if (Array.isArray(value)) {
          value.forEach((v, index) => {
            data.append(`${key}${index}`, v)
          })
        } else {
          if (value instanceof File) {
            data.append(key, value, value.name)
          } else {
            data.append(key, value.toString())
          }
        }
      }
    })
  return data
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGetNotesQuery = ({ objectId }: any) => {
  const activeOfficeId = localStorage.getItem('activeOfficeId')

  return useQuery({
    queryKey: ['notes', objectId],
    queryFn: () =>
      get(`notes?objectId=eq:${objectId}&officeId=eq:${activeOfficeId}`),
    // refetchInterval: 4000,
    select: (data) => {
      return data.data.map((com: DO_Note) => ({
        ...com,
        date: parseISO(com.createdAt),
        userCreated: {
          ...com.createdBy,
          firstName: com.createdBy.userName.split(' ')[0] || null,
          lastName: com.createdBy.userName.split(' ')[1] || null,
        },
        replies: com.replies.map((reply: DO_Note) => ({
          ...reply,
          date: parseISO(reply.createdAt),
          userCreated: {
            ...reply.createdBy,
            firstName: reply.createdBy.userName.split(' ')[0] || null,
            lastName: reply.createdBy.userName.split(' ')[1] || null,
          },
        })),
      }))
      // .sort((a: any, b: any) => a.date - b.date) // todo: do the sorting on BE
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useNotesrepliesQuery = ({ noteId, enabledQuery }: any) => {
  return useQuery({
    queryKey: ['note', noteId],
    queryFn: () => get(`notes/${noteId}`),
    enabled: enabledQuery,
  })
}

interface CreateMutationProp {
  callback?: () => void
  noteType?: string
  objectId?: string
}
const getMentionedUsers = (mentionedUsers: UserMention[]) =>
  mentionedUsers
    ? uniq(mentionedUsers.map((x: UserMention) => x.id)).join(',')
    : ''

export const useCreateNoteMutation = ({
  callback,
  objectId,
  noteType,
}: CreateMutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: NewNote) => {
      const activeOfficeId = localStorage.getItem('activeOfficeId')

      const data = transformToFormData({
        ...values,
        noteType: noteType,
        objectId,
        content: values.content,
        mentions: getMentionedUsers(values?.mentionedUsers || []),
        // files: values.attachments,

        officeId: activeOfficeId,
      })

      return postFormData('notes', data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notes', objectId])
      callback && callback()
    },
    onError: () => {
      queryClient.invalidateQueries(['notes', objectId])
      toast.error('An error happened! Note could not be added')
      callback && callback()
    },
  })
}
interface UpdateMutationProp {
  callback?: () => void
  noteType?: string
  objectId?: string
}
export const useUpdateNoteMutation = ({
  callback,
  objectId,
  noteType,
}: UpdateMutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (values: NewNote) => {
      return putFormData(
        `notes/${values.id}`,
        transformToFormData({
          noteType,
          ...values,
          attachments: null,
          mentions: getMentionedUsers(values?.mentionedUsers || []),
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          files: values?.attachments?.filter((x: any) => !x.hash) || [],
        }),
      )
    },
    onMutate: async (newData) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: ['notes', objectId] })

      // Snapshot the previous value
      const previousTodos = queryClient.getQueryData(['notes', objectId])

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      queryClient.setQueryData(['notes', objectId], (old: any) => {
        const oldItem = old.data.find((item: Note) => item.id === newData.id)
        return { data: [...old.data, { ...oldItem, ...newData }] }
      })

      // Return a context object with the snapshotted value
      return { previousTodos }
    },

    onSuccess: () => {
      queryClient.invalidateQueries(['notes', objectId])
      callback && callback()
    },
    onError: () => {
      queryClient.invalidateQueries(['notes', objectId])
      toast.error('An error happened! Note could not be updated')
      callback && callback()
    },
  })
}

export const useDeleteNoteMutation = ({
  callback,
  noteType,
}: CreateMutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (id: string) => {
      return postDelete(`notes/${id}`)
    },
    onSuccess: () => {
      //   toast.success('Success! Lead was created')
      queryClient.invalidateQueries([noteType])
      callback && callback()
    },
    onError: () => {
      //   toast.error('Lead could not be created! Please try again ')
      callback && callback()
    },
  })
}

interface NoteMutationProp {
  callback?: () => void
  objectId: string
}
export const useCreateReplyMutation = ({
  objectId,
  callback,
}: NoteMutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ noteId, ...values }: Reply) => {
      const data = transformToFormData(values)

      return postFormData(`notes/${noteId}/reply`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notes', objectId])
      callback && callback()
    },
    onError: () => {
      // toast.error('Lead could not be created! Please try again ')
      callback && callback()
    },
  })
}

export const useUpdateReplyMutation = ({ callback }: NoteMutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ noteId, ...values }: Reply) => {
      const data = transformToFormData(values)

      return putFormData(`notes/${noteId}/reply`, data)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notes'])
      callback && callback()
    },
    onError: () => {
      // toast.error('Lead could not be created! Please try again ')
      callback && callback()
    },
  })
}
