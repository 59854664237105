import { Notes as NotesSection } from 'src/common/components/notes/notes'
import { useGetUsers } from 'src/common/apis'
import { QuotesNotesProps as NotesProps } from 'src/common/types/Quote'

export const Notes = ({ quoteId }: NotesProps) => {
  const getUsersQuery = useGetUsers({})
  return (
    <div>
      {quoteId && (
        <NotesSection
          users={getUsersQuery.data}
          objectId={quoteId}
          noteType="quote"
        />
      )}
    </div>
  )
}
