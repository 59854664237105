/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Stack,
  SvgIcon,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline'
import PlusIcon from '@untitled-ui/icons-react/build/esm/Plus'
import RoleGuard from 'src/common/guards/role-guard'
import CreateModal from './create-purchases-modal'
import React, { useContext } from 'react'
import ConfirmationDialog from 'src/common/components/dialogs/ConfirmationDialog'
import { ModalContext } from 'src/common/contexts/modal-context'
import { useModal } from 'src/common/hooks/use-modal'
import { useDeletePurchaseOrderMutation } from './usePurchasesRequests'
import EditModal from './modal'
import { NoRecordsFound } from 'src/common/components/no-records'
import { Vendor } from 'src/modules/vendors/formConfigs'

interface QuotePurchases {
  quote: any
  isLoading: boolean
}

type PurchaseOrder = {
  id: string
  basePrice: string
  vendorId?: { id: string; name: string }
  vendor?: Vendor
  description: string
}

const PurchaseOrdersOverview = ({ quote, isLoading }: QuotePurchases) => {
  const modalContext = useContext(ModalContext)

  if (!modalContext) {
    throw new Error(
      'ModalContext is null. Make sure you are rendering inside ModalProvider.',
    )
  }

  const { modals, openModal, closeModal, isOpenedInlineModal } = modalContext

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] =
    React.useState<PurchaseOrder | null>(null)

  const {
    handleClose: handleCloseConfirmation,
    handleOpen: handleOpenConfirmation,
    open: openConfirmation,
  } = useModal()

  const deletePurchaseOrderMutation = useDeletePurchaseOrderMutation({
    callback: () => {
      setSelectedPurchaseOrder(null)
    },
    quoteId: quote?.id,
  })

  const confimrDelete = () => {
    if (selectedPurchaseOrder) {
      deletePurchaseOrderMutation.mutate(selectedPurchaseOrder.id as string)
    }
    handleCloseConfirmation()
  }

  const handleDelete = (row: any) => {
    setSelectedPurchaseOrder(row)

    handleOpenConfirmation()
  }

  const handleEdit = (el: any) => {
    setSelectedPurchaseOrder(el)
    openModal('edit-purchase-order-modal', true)
  }

  if (isLoading || !quote.id) {
    return null
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: isOpenedInlineModal(['taskmodal']) ? 0 : 1,
        py: 4,
      }}
    >
      <Box maxWidth="xl" sx={{}}>
        <Stack spacing={4}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant="h4">Purchases</Typography>
            </Stack>
            <Stack alignItems="center" direction="row" spacing={3}>
              <RoleGuard
                scopes={['quote.write']}
                errorProps={{ disabled: true }}
              >
                <Button
                  startIcon={
                    <SvgIcon>
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={() => openModal('create-purchase-order-modal', true)}
                  id="appSettings-purchase-order-create"
                >
                  Create new Purchase
                </Button>
              </RoleGuard>
            </Stack>
          </Stack>

          <Box p={1}>
            <Box>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <TableCell>Base Price</TableCell>
                    <TableCell>Vendor</TableCell>
                    <TableCell>Purchase Description</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quote?.purchases?.map(
                    (row: PurchaseOrder, index: number) => (
                      <TableRow key={row.id || index}>
                        <TableCell
                          component="th"
                          scope="row"
                          onClick={() => handleEdit(row)}
                        >
                          {row.basePrice}
                        </TableCell>
                        <TableCell onClick={() => handleEdit(row)}>
                          {row?.vendor?.name}
                        </TableCell>
                        <TableCell onClick={() => handleEdit(row)}>
                          {row.description}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="Edit"
                            title="Edit"
                            onClick={() => handleEdit(row)}
                          >
                            <DriveFileRenameOutlineIcon />
                          </IconButton>

                          <IconButton
                            title="Delete"
                            aria-label="delete"
                            onClick={() => {
                              handleDelete(row)
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>

              {quote?.purchases?.length === 0 && (
                <Grid
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  p={2}
                >
                  <NoRecordsFound />
                </Grid>
              )}
              <ConfirmationDialog
                buttonId="sales-quotes-purhcase-orders-delete-confirm"
                open={openConfirmation}
                title="Confirm Delete"
                message="Are you sure you want to delete this purchase?"
                onConfirm={confimrDelete}
                onCancel={() => handleCloseConfirmation()}
              />

              <CreateModal
                key="create-purchase-order-modal"
                handleClose={() => {
                  closeModal('create-purchase-order-modal')
                  setSelectedPurchaseOrder(null)
                }}
                open={
                  !!modals.find((x) => x.id == 'create-purchase-order-modal')
                    ?.open
                }
                quoteId={quote?.id}
              />
              <EditModal
                key="edit-purchases-modal"
                handleClose={() => {
                  closeModal('edit-purchase-order-modal')
                  setSelectedPurchaseOrder(null)
                }}
                open={
                  !!modals.find((x) => x.id == 'edit-purchase-order-modal')
                    ?.open
                }
                initialValues={{
                  ...selectedPurchaseOrder,
                  vendorId: {
                    ...selectedPurchaseOrder?.vendor,
                    value: selectedPurchaseOrder?.vendor?.id,
                    label: selectedPurchaseOrder?.vendor?.name,
                  },
                }}
                quoteId={quote?.id}
              />
            </Box>{' '}
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}

export default PurchaseOrdersOverview
