/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'
import { get, post } from 'src/common/utils/api'

export const useGetNotificationsQuery = ({ officeId, userid }: any) => {
  return useQuery(['notifications'], {
    queryFn: () =>
      get(
        `common/search?term=&index=notification&page=1&perPage=100&officeId=${officeId}&filters="user_id=${userid}"`,
        {},
        false,
      ),
    select: (data: any) => data?.data,
  })
}

export const useReadNotification = ({ id, callback }: any) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () => {
      return post(`notifications/${id}/opened`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['notifications'])
    },
    onError: () => {
      callback && callback()
      toast.error(
        'An error happened, notification could not be marked as read!',
      )
    },
  })
}
