/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFuture } from 'date-fns'
import { convertEnumToOptions } from 'src/common/utils/helpers'
import * as Yup from 'yup'

export const commodityForm = {
  collis: [
    {
      quantity: null,
      packageType: null,
      dimensions: {
        width: null,
        length: null,
        height: null,
        unit: 'cm',
        volume: {
          value: null,
          unit: 'm3',
        },
      },
      netWeight: {
        value: null,
        unit: 'kg',
      },
      grossWeight: {
        value: null,
        unit: 'kg',
      },
    },
  ],
}

export const initialValues = {
  // Main Fields
  direction: '',
  transportBy: 'rulewave',
  mot: '',
  status: 'new',
  externalReference: '',
  incotermCustomerBuyer: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfArrival: '',
  boltricsReferences: [{ value: null }],
  shipper: null,
  consignee: null,
  notify: null,

  // Commodity Fields
  commodity: commodityForm,
}

export const validationSchema = Yup.object().shape({
  direction: Yup.string().required('Shipment direction is required'),
  transportBy: Yup.string().required('Transport by is required'),
  mot: Yup.string().required('Mode Of Transport is required'),
  externalReference: Yup.string().required('Reference external is required'),
  transportationResponsibilitiesRulewave: Yup.array()
    .of(Yup.string())
    .when('transportBy', {
      is: (val: string) => val === 'rulewave',
      then: (schema) =>
        schema.min(1, 'At least one responsibility must be selected'),
      otherwise: (schema) => schema.notRequired(),
    }),
})

export const commodityValidationSchema = Yup.object().shape({
  shipmentProducts: Yup.array()
    .of(
      Yup.object().shape({
        productNumber: Yup.mixed().required('Part number is required'),
        commodityDescription: Yup.string().required(
          'Commodity description is required',
        ),
        htcCode: Yup.string().required('HTC code is required'),

        countryOfOrigin: Yup.string().test(
          'countryOfOrigin-test',
          'Country of origin is required for import and export',
          function (value) {
            const { direction } = this.parent

            if (direction === 'direct') return true
            else {
              return !!value
            }
          },
        ),
        // .min(1, 'At least one part number  isrequired'),
        customsStatus: Yup.string().test(
          'customsStatus-test',
          'Customs status is required for import and export',
          function (value) {
            const { direction } = this.parent
            return direction === 'direct' || !!value
          },
        ),
      }),
    )
    .min(1, 'At least one product number is required'),
  collis: Yup.array()
    .of(
      Yup.object().shape({
        quantity: Yup.string().required('Required'),
        packageType: Yup.string().required('Required'),
        dimensions: Yup.object().shape({
          unit: Yup.string().required('Required'),
          volume: Yup.object().shape({
            value: Yup.string().required('Volume required'),
            unit: Yup.string().required('Volume unit required'),
          }),
        }),
        netWeight: Yup.object().shape({
          value: Yup.string().required('Required'),
          unit: Yup.string().required('Required'),
        }),
        grossWeight: Yup.object().shape({
          value: Yup.string().required('Required'),
        }),
      }),
    )
    .min(1, 'At least one item is required'),
})

// Air Transportation Fields
export const air = {
  loadingAddress: '',
  dischargeAddress: '',
  status: '',
  carrier: '',
  airLegs: [],

  awbType: '',
  awbNumber: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}

// Ferry Transportation Fields
export const ferry = {
  shippingType: '',
  loadingAddress: '',
  dischargeAddress: '',
  loadingPort: '',
  dischargePort: '',
  carrier: '',
  ferryName: '',
  voyageNumber: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}
// Road Transportation Fields
export const road = {
  shippingType: '',
  loadingAddress: '',
  dischargeAddress: '',
  vehicleType: '',
  ldm: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
  trucks: [],
}
// Parcel Transportation Fields
export const parcel = {
  loadingAddress: '',
  dischargeAddress: '',
  courierAwb: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}
// Rail Transportation Fields
export const rail = {
  loadingAddress: '',
  dischargeAddress: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}

// Sea Transportation Fields
export const sea = {
  shippingType: '',
  loadingAddress: '',
  dischargeAddress: '',
  carrier: '',
  blNumber: '',
  seaLegs: [
    {
      loadingPort: null,
      dischargePort: null,
      vesselName: null,
      voyageNumber: null,
    },
  ],
  containers: [],
  containerType: '',
  shippersOwnedEquipment: '',
  importTerminalPickupAddress: '',
  emptyDeliveryDepot: '',
  seaInboundStatus: '',

  closingShippingInstructions: null,
  closingVGM: null,
  closingContainerDelivery: null,
  closingIMO: null,
  emptyPickupDepot: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}

// Vendor Fields
export const vendor = {
  loadingAddress: '',
  dischargeAddress: '',
  estimatedTimeOfPickup: null,
  actualTimeOfPickup: '',
  estimatedTimeOfDeparture: null,
  actualTimeOfDeparture: '',
  estimatedTimeOfArrival: '',
  actualTimeOfArrival: '',
}

const dateValidations = {
  estimatedTimeOfPickup: Yup.date().nullable(),
  estimatedTimeOfDeparture: Yup.date()
    .nullable()
    .test(
      'is-after-pickup',
      'Estimated Time of Departure cannot be before the Estimated Time of Pickup',
      function (value) {
        const pickupDate: any = this.parent.estimatedTimeOfPickup
        return !pickupDate || !value || value >= pickupDate
      },
    ),
  estimatedTimeOfArrival: Yup.date()
    .nullable()
    .test(
      'is-after-departure',
      'Estimated Time of Arrival cannot be before the Estimated Time of Departure',
      function (value) {
        const pickupDate = this.parent.estimatedTimeOfDeparture
        return !pickupDate || !value || value >= pickupDate
      },
    ),
  actualTimeOfPickup: Yup.date()
    .nullable()
    .test(
      'is-not-future',
      'Actual Time of Pickup cannot be in the future',
      (value) => (value ? !isFuture(new Date(value)) : true),
    ),
  actualTimeOfDeparture: Yup.date()
    .nullable()
    .test(
      'is-after-apickup',
      'Actual Time of Departure cannot be before the Actual Time of Pickup',
      function (value) {
        const pickupDate = this.parent.actualTimeOfPickup
        return !pickupDate || !value || value >= pickupDate
      },
    ),

  actualTimeOfArrival: Yup.date()
    .nullable()
    .test(
      'is-after-adeparture',
      'Actual Time of Arrival cannot be before the Actual Time of Departure',
      function (value) {
        const pickupDate = this.parent.actualTimeOfDeparture
        return !pickupDate || !value || value >= pickupDate
      },
    ),
}
export const motAirValidationSchema = Yup.object().shape({
  loadingAddress: Yup.mixed().required('Loading address is required'),
  awbNumber: Yup.string()
    .matches(
      /^\d{3}-\d{8}$/,
      'AWB number must be in the format of 3 digits followed by a dash and 8 digits',
    )
    .required('WB number is required'),
  //   'legs' is an array of objects each containing loadingPort, dischargePort, flightNumber
  airLegs: Yup.array().of(
    Yup.object().shape({
      loadingPort: Yup.mixed().required('Loading port is required'),
      dischargePort: Yup.mixed().required('Discharge port is required'),
      flightNumber: Yup.string()
        .required('Flight number is required')
        .matches(
          /^[A-Za-z0-9]{2}\d{4}$/,
          'Flight number must be 2 alphanumeric characters followed by 4 digits',
        ),
    }),
  ),
  // .required('At least one item is required')
  // .min(1, 'At least one item is required'),
  ...dateValidations,
})

export const motSeaValidationSchema = Yup.object().shape({
  seaLegs: Yup.array().of(
    Yup.object().shape({
      loadingPort: Yup.mixed()
        .nullable()
        .test(
          'fill-all-or-none',
          'Loading port is required',
          function (value, context) {
            const { loadingPort, dischargePort, vesselName, voyageNumber } =
              context.parent
            const fields = [dischargePort, vesselName, voyageNumber]
            const hasValue = fields.some((field) => field != null)
            return !hasValue || !(loadingPort === null)
          },
        ),
      dischargePort: Yup.mixed()
        .nullable()
        .test(
          'fill-all-or-none',
          'Discharge port is required',
          function (value, context) {
            const { loadingPort, dischargePort, vesselName, voyageNumber } =
              context.parent
            const fields = [loadingPort, vesselName, voyageNumber]
            const hasValue = fields.some((field) => field != null)
            return !hasValue || !(dischargePort === null)
          },
        ),
      vesselName: Yup.mixed()
        .nullable()
        .test(
          'fill-all-or-none',
          'Vessel name is required',
          function (value, context) {
            const { loadingPort, dischargePort, vesselName, voyageNumber } =
              context.parent
            const fields = [loadingPort, dischargePort, voyageNumber]
            const hasValue = fields.some((field) => field != null)
            return !hasValue || !(vesselName === null)
          },
        ),
      voyageNumber: Yup.string().nullable(),
      // .test(
      //   'fill-all-or-none',
      //   'Voyage number is required',
      //   function (value, context) {
      //     const { loadingPort, dischargePort, vesselName, voyageNumber } =
      //       context.parent
      //     const fields = [loadingPort, dischargePort, vesselName]
      //     const hasValue = fields.some((field) => field != null)
      //     return !hasValue || !(voyageNumber === null)
      //   },
      // ),
    }),
  ),
  shippingType: Yup.mixed().required('Shipping type  is required'),
  status: Yup.mixed().required('Status is required'),
  blNumber: Yup.string()
    // .matches(/^[a-zA-Z0-9]+$/, 'String must contain only letters and numbers')
    // .min(12, 'String must be between 12 and 16 characters')
    // .max(16, 'String must be between 12 and 16 characters')
    .nullable(),
  ...dateValidations,
})

export const seaContainerValidation = Yup.object().shape({
  containerType: Yup.mixed().required('Container type  is required'),
  containerNumber: Yup.string().nullable(),
  // .required('Container number  is required'),
})

export const motRoadValidationSchema = Yup.object().shape({
  // ...dateValidations,
  // loadingAddress: Yup.mixed().required('Loading address is required'),
  // dischargeAddress: Yup.mixed().required('Discharge address is required'),
  // vehicleType: Yup.mixed().required('Vehicle typeis required'),
  // shippingType: Yup.mixed().required('Shipping type is required'),
})
export const motRailValidationSchema = Yup.object().shape({
  ...dateValidations,
  loadingAddress: Yup.mixed().required('Loading address is required'),
  dischargeAddress: Yup.mixed().required('Discharge address is required'),
})
export const motFerryValidationSchema = Yup.object().shape({
  shippingType: Yup.mixed().required('Shipping type is required'),
  loadingAddress: Yup.mixed().test(
    'loadingAddress-test',
    'Loading address is required for OUTBOUND shipments',
    function (value) {
      return this.parent.direction !== ShipmentDirection.export || !!value
    },
  ),
  carrier: Yup.mixed().required('Carrier is required'),
  ...dateValidations,
})
export const motParcelValidationSchema = Yup.object().shape({
  ...dateValidations,
  loadingAddress: Yup.mixed().required('Loading address is required'),
  dischargeAddress: Yup.mixed().required('Discharge address is required'),
  courierAwb: Yup.mixed().required('Courier AWB# is required'),
  ...dateValidations,
})
export const motVendorValidationSchema = Yup.object().shape({
  // dischargeAddress: Yup.mixed().required('Discharge address is required'),
})

export const today = new Date()
today.setHours(0, 0, 0, 0) // Set time to midnight to consider the entire day

export const editValidationSchema = Yup.object()

export enum ShipmentDirection {
  import = 'Import',
  export = 'Export',
  direct = 'Direct Shipment',
}

export const detapartamentShippmentDirection = {
  IB: 'import',
  OB: 'export',
  AIR: '',
  NULL: '',
} as const // using 'as const' ensures the object keys and values are readonly and literal types
export const detapartamentShippmentMOT = {
  IB: 'sea',
  OB: 'sea',
  AIR: 'air',
  FF: 'direct',
  PH: '',
  NULL: '',
} as const // using 'as const' ensures the object keys and values are readonly and literal types

// Define the possible keys for the department
export type DepartmentKeys = keyof typeof detapartamentShippmentDirection &
  keyof typeof detapartamentShippmentMOT

export enum TransportBy {
  rulewave = 'Rulewave',
  external = 'External',
}

export enum MOT {
  air = 'Air',
  sea = 'Sea',
  road = 'Road',
  rail = 'Rail',
  parcel = 'Parcel',
  ferry = 'Ferry',
}
export type MOT_Type = 'Air' | 'Sea' | 'Road' | 'Rail' | 'Parcel' | 'Ferry'

export const UOM = {
  "20'DV": "20'DV",
  "20'FR": "20'FR",
  "20'OT": "20'OT",
  "20'RF": "20'RF",
  "20'SOC": "20'SOC",
  "20'TK": "20'TK",
  "40'DV": "40'DV",
  "40'FR": "40'FR",
  "40'HC": "40'HC",
  "40'OT": "40'OT",
  "40'RF": "40'RF",
  "40'SOC": "40'SOC",
  "45'HC PWC": "45'HC PWC",
  "45'HQ": "45'HQ",
  FTL: 'FTL',
  LTL: 'LTL',
  Lumpsum: 'Lumpsum',
  'Per 25 KG bag': 'Per 25 KG bag',
  'Per 25 KG can': 'Per 25 KG can',
  'Per B/L': 'Per B/L',
  'Per bag': 'Per bag',
  'Per bag per day': 'Per bag per day',
  'Per bigbag': 'Per bigbag',
  'Per calendar day': 'Per calendar day',
  'Per CBM': 'Per CBM',
  'Per colli': 'Per colli',
  'Per colli / per day': 'Per colli / per day',
  'Per colli / per week': 'Per colli / per week',
  'Per colli / per month': 'Per colli / per month',
  'Per container': 'Per container',
  'Per container / per day': 'Per container / per day',
  'Per document': 'Per document',
  'Per hour': 'Per hour',
  'Per hour per staff member': 'Per hour per staff member',
  'Per item no.': 'Per item no.',
  'Per kilo': 'Per kilo',
  'Per label': 'Per label',
  'Per M2 per day': 'Per M2 per day',
  'Per meter': 'Per meter',
  'Per month': 'Per month',
  'Per order': 'Per order',
  'Per pallet': 'Per pallet',
  'Per Pallet / Per Day': 'Per Pallet / Per Day',
  'Per Pallet / Per Week': 'Per Pallet / Per Week',
  'Per pallet / per month': 'Per pallet / per month',
  'Per picture': 'Per picture',
  'Per strap': 'Per strap',
  'Per Teu': 'Per Teu',
  'Per truck': 'Per truck',
  'Per truck / per calendar day': 'Per truck / per calendar day',
  'W/M': 'W/M',
  'Per Mt': 'Per MT',
  Percentage: 'Percentage',
  'Per shipment': 'Per Shipment',
} as const

export enum ShipmentStatus {
  new = 'New',
}

export enum MeasureUnit {
  mm = 'mm',
  cm = 'cm',
  m = 'm',
  in = 'in',
  f = 'ft',
}

export enum WeightUnit {
  kg = 'kg',
  lb = 'lb',
  mt = 'mt',
}

export enum HazardousMaterials {
  explosives = 'Explosives',
  flammableGases = 'Flammable gases',
  nonFlammableNonToxicGases = 'Non-flammable, non-toxic gases',
  toxicGases = 'Toxic gases',
  flammableLiquids = 'Flammable liquids',
  flammableSolids = 'Flammable solids',
  substancesLiableToSpontaneousCombustion = 'Substances liable to spontaneous combustion',
  substancesEmittingFlammableGasesWhenInContactWithWater = 'Substances which, in contact with water, emit flammable gases',
  oxidizingSubstances = 'Oxidizing substances',
  organicPeroxides = 'Organic peroxides',
  toxicSubstances = 'Toxic substances',
  infectiousSubstances = 'Infectious substances',
  radioactiveMaterials = 'Radioactive materials',
  corrosives = 'Corrosives',
  miscellaneousDangerousGoods = 'Miscellaneous Dangerous Goods',
}

export const HazardousMaterialsOptions =
  convertEnumToOptions(HazardousMaterials)

export enum PacgingGroup {
  I = 'I',
  II = 'II',
  III = 'III',
}
export const PacgingGroupOptions = convertEnumToOptions(PacgingGroup)

export enum PackageType {
  Bag = 'Bag',
  Bigbag = 'Bigbag',
  Box = 'Box',
  Bulk = 'Bulk',
  Bundle = 'Bundle',
  Cans = 'Cans',
  Container = 'Container',
  Crate = 'Crate',
  Drum = 'Drum',
  IBC = 'IBC',
  Pallet = 'Pallet',
  Pipe = 'Pipe',
  Pouch = 'Pouch',
  Skid = 'Skid',
  Piece = 'Piece',
  Reel = 'Reel',
  Loose = 'Loose',
}
export const PackageTypeOptions = convertEnumToOptions(PackageType)

export enum EmSFire {
  F_A = 'F-A',
  F_B = 'F-B',
  F_C = 'F-C',
  F_D = 'F-D',
  F_E = 'F-E,',
  F_F = 'F-F',
  F_G = 'F-G',
  F_H = 'F-H',
  F_I = 'F-I',
  F_J = 'F-J',
}

export const EmSFireOptions = convertEnumToOptions(EmSFire)

export enum EmSSpill {
  S_A = 'S-A',
  S_B = 'S-B',
  S_C = 'S-C',
  S_D = 'S-D',
  S_E = 'S-E',
  S_F = 'S-F',
  S_G = 'S-G',
  S_H = 'S-H',
  S_I = 'S-I',
  S_J = 'S-J',
  S_K = 'S-K',
  S_L = 'S-L',
  S_M = 'S-M',
  S_N = 'S-N',
  S_O = 'S-O',
  S_P = 'S-P',
  S_Q = 'S-Q',
  S_R = 'S-R',
  S_S = 'S-S',
  S_T = 'S-T',
  S_U = 'S-U',
  S_V = 'S-V',
  S_W = 'S-W',
  S_X = 'S-X',
  S_Y = 'S-Y',
  S_Z = 'S-Z',
}

export const EmSSpillOptions = convertEnumToOptions(EmSSpill)
export enum TunnelRestrictionCode {
  A = 'A',
  B = 'B',
  B1000C = 'B1000C',
  BD = 'B/D',
  BE = 'B/E',
  C = 'C',
  C5000D = 'C5000D',
  CD = 'C/D',
  CE = 'C/E',
  D = 'D',
  DE = 'D/E',
  E = 'E',
}
export const TunnelRestrictionCodeOptions = convertEnumToOptions(
  TunnelRestrictionCode,
)

export enum ShippingTypes {
  BB = 'BB',
  FCL = 'FCL',
  LCL = 'LCL',
  RO_RO = 'RO/RO',
}

export enum Sea_Inbound_Status {
  GATE_OUT_EMPTY = 'Gate out empty',
  ARRIVAL_IN = 'Arrival in',
  LOADED = 'Loaded',
  VESSEL_DEPARTED = 'Vessel departed',
  VESSEL_ARRIVED = 'Vessel arrived',
  DISCHARGED = 'Discharged',
  DEPARTURE_FROM = 'Departure from',
  GATE_IN_EMPTY = 'Gate in empty',
}
export enum Air_Status {
  BOOKED = 'Booked',
  RECEIVED = 'Received',
  DEPARTED = 'Departed',
  ARRIVED = 'Arrived',
  NOTIFIED = 'Notified',
  DELIVERED = 'Delivered',
}

export const Air_StatusOptions = convertEnumToOptions(Air_Status, true)
export const Sea_Inbound_StatusOptions = convertEnumToOptions(
  Sea_Inbound_Status,
  true,
)
export const ShippingTypesOptions = convertEnumToOptions(ShippingTypes)

export enum ShippingTypesFerry {
  FTL = 'FTL',
  LTL = 'LTL',
}

export const ShippingTypesFerryOptions =
  convertEnumToOptions(ShippingTypesFerry)

export enum VehicleTypeFerry {
  curtainsideTrailer136M = 'Curtainside Trailer (13.6m)',
  boxTrailer136M = 'Box Trailer (13.6m)',
  boxTrailer136MFrigo = 'Box Trailer (13.6m) Frigo',
  megaTrailer = 'Mega Trailer',
  flatbedTrailer = 'Flatbed Trailer',
  combinationTruckCurtain = 'Combination Truck Curtain',
  combinationTruckSwayBody = 'Combination Truck Sway Body',
  vanBox = 'Van Box',
  vanSprinterType = 'Van Sprinter Type',
  vanSmall = 'Van Small',
  Car = 'Car',
}

export const VehicleTypeFerryOptions = convertEnumToOptions(VehicleTypeFerry)

export enum AWB_Type {
  MAWB = 'MAWB',
  HAWB = 'HAWB',
  PAX = 'PAX',
  CAO = 'CAO',
}
export enum CustomsStatus {
  FREE = 'FREE',
  BOND = 'BOND',
}

export const CustomsStatusOptions = convertEnumToOptions(CustomsStatus)
export const AWB_TypeOptions = convertEnumToOptions(AWB_Type)

export const ShipmentDirectionOptions = convertEnumToOptions(
  ShipmentDirection,
  true,
)

export const TransportByOptions = convertEnumToOptions(TransportBy, true)
export const MOTOptions = convertEnumToOptions(MOT, true)
export const UOMOptions = convertEnumToOptions(UOM, true)
export const ShipmentStatusOptions = convertEnumToOptions(ShipmentStatus, true)

export const MeasureUnitOptions = convertEnumToOptions(MeasureUnit)
export const WeightUnitOptions = convertEnumToOptions(WeightUnit)

export const transportFormValidation = {
  sea: motSeaValidationSchema,
  air: motAirValidationSchema,
  road: motRoadValidationSchema,
  rail: motRailValidationSchema,
  ferry: motFerryValidationSchema,
  parcel: motParcelValidationSchema,
  vendor: motVendorValidationSchema,
}

export const europeanCountries = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]
