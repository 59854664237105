import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-hot-toast'
import { INDEXES } from 'src/common/apis/constants'
import { post } from 'src/common/utils/api'

interface SubmitValues {
  id?: string
  basePrice: string | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  vendorId: any
  description?: string
}

interface mutationProp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback?: (data?: any) => void
  quoteId: string
}

export const useCreatePurchaseOrderMutation = ({
  callback,
  quoteId,
}: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) => post(`sales/quotes/${quoteId}/add-purchase`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Purchase was created successfully`)
      queryClient.invalidateQueries(['quote', quoteId])
      callback && callback()
    },
    onError: () => {
      toast.error(`Purchase could not be created! Please try again`)
    },
  })
}

export const useUpdatePurchaseOrderMutation = ({
  callback,
  quoteId,
}: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      values,
      setFieldError,
    }: {
      values: SubmitValues
      setFieldError?: (field: string, value: string | undefined) => void
    }) =>
      post(`sales/quotes/${quoteId}/update-purchase`, values, setFieldError),
    onSuccess: () => {
      toast.success(`Success! Purchase was updated`)
      queryClient.invalidateQueries([INDEXES.QUOTE])
      callback && callback()
    },
    onError: () => {
      toast.error(`Purchase could not be updated! Please try again`)
    },
  })
}

export const useDeletePurchaseOrderMutation = ({
  callback,
  quoteId,
}: mutationProp) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (purchaseOrderId: string) =>
      post(`sales/quotes/${quoteId}/remove-purchase`, {
        quotePurchaseId: purchaseOrderId,
      }),
    onSuccess: () => {
      toast.success(`Purchase was deleted successfully`)
      queryClient.invalidateQueries(['quote', quoteId])
      callback && callback()
    },
    onError: () => {
      toast.error(`Purchase could not be deleted! Please try again`)
    },
  })
}
