import { useState } from 'react'
import { User } from 'src/common/types/User'
import { Alert, Box, LinearProgress } from '@mui/material'
import { NewNote } from './new-note'
import { NoteComponent } from './note'
import { Note, NewNote as NewNoteType } from './types'
import { styled } from '@mui/system'
import {
  useCreateNoteMutation,
  useGetNotesQuery,
  useUpdateNoteMutation,
} from 'src/common/hooks/use-notes-api'
import NoteSkeleton from './notes-skeleton'

const NoteBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

interface NotesProps {
  objectId: string
  noteType: string
  users: User[]
}

export const Notes = ({ objectId, noteType, users }: NotesProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const notesQuery = useGetNotesQuery({ objectId, users })
  const notes = notesQuery?.data || []

  const createMutation = useCreateNoteMutation({
    noteType: noteType,
    objectId,
    callback: () => {
      console.log('created callback')
    },
  })

  const updateNoteMutation = useUpdateNoteMutation({
    objectId: objectId,
    noteType,
  })
  // const getMentionedUsers = (mentionedUsers: UserMention[]) =>
  //   mentionedUsers
  //     ? uniq(mentionedUsers.map((x: UserMention) => x.id)).join(',')
  //     : ''

  const createNote = (note: NewNoteType) => {
    createMutation.mutate({
      content: note.content,
      mentionedUsers: note.mentionedUsers || [],
      attachments: note.attachments,
    })
    return createMutation
  }

  const updateNote = (note: NewNoteType) => {
    updateNoteMutation.mutate(note)
    return updateNoteMutation
  }

  return (
    <Box>
      {errorMessage && (
        <Alert
          color="error"
          variant="outlined"
          onClose={() => setErrorMessage('')}
        >
          {errorMessage}
        </Alert>
      )}

      <NoteBox>
        <NewNote
          isLoading={createMutation.isLoading}
          users={users}
          onNoteCreate={(note) =>
            createNote({
              date: new Date(),
              ...note,
            })
          }
        />
      </NoteBox>

      {!notesQuery.isLoading && notesQuery.isFetching && (
        <LinearProgress color="inherit" sx={{ height: '2px' }} />
      )}
      {notesQuery.isLoading && <NoteSkeleton />}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column-reverse',
          overflow: 'auto',
        }}
      >
        {notes.map((note: Note) => (
          <NoteComponent
            note={note}
            key={note.id}
            users={users}
            allowReply
            updateNote={updateNote}
            noteType={noteType}
            objectId={objectId}
            isUpdating={updateNoteMutation.isLoading}
          />
        ))}
      </Box>
    </Box>
  )
}
