/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import ButtonGroup from '@mui/material/ButtonGroup'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import ListItemIcon from '@mui/material/ListItemIcon'
import { makeStyles } from '@mui/styles'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton } from '@mui/material'

interface Option {
  title: string
  handleClick: (e: any) => void
  hideThisOption?: boolean
  disabled?: boolean
  isLoading?: boolean
  icon?: any
  id?: string
}

interface ButtonWithOptionsProps {
  options?: Option[]
  isLoading?: boolean
  variant?: 'outlined' | 'contained'
  size?: 'small' | 'medium'
  icon?: any
  id?: string
}
const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})

const IButtonWithOptions = ({
  options = [],
  isLoading,
  variant = 'outlined',
  size = 'medium',
  icon,
  id,
}: ButtonWithOptionsProps) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return
    }

    setOpen(false)
  }
  const filteredOptions = options.filter((x) => !x.hideThisOption)

  if (filteredOptions.length === 0) return <></>

  return (
    <>
      <ButtonGroup
        variant={variant}
        ref={anchorRef}
        aria-label="Button group with a nested menu"
      >
        <IconButton id={id} onClick={handleToggle} size={size}>
          {icon || <MoreVertIcon />}
        </IconButton>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {filteredOptions?.map((option) => (
                    <MenuItem
                      key={option.title}
                      onClick={option.handleClick}
                      className={classes.menuItem}
                      disabled={
                        option.disabled || option.isLoading || isLoading
                      }
                      id={id + '-' + option.title}
                    >
                      {option.icon ? (
                        <ListItemIcon>{option.icon}</ListItemIcon>
                      ) : (
                        ''
                      )}
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}

export default IButtonWithOptions
